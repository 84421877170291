html {
  -webkit-text-size-adjust: 100%;
  line-height: 1.15;
}

body {
  margin: 0;
}

h1 {
  margin: .67em 0;
  font-size: 2em;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

pre {
  font-family: monospace, monospace;
  font-size: 1em;
}

a {
  background-color: #0000;
}

abbr[title] {
  text-decoration: underline;
  border-bottom: none;
  text-decoration: underline dotted;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

img {
  border-style: none;
}

button, input, optgroup, select, textarea {
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
}

button, input {
  overflow: visible;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner, [type="button"]::-moz-focus-inner, [type="reset"]::-moz-focus-inner, [type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring, [type="button"]:-moz-focusring, [type="reset"]:-moz-focusring, [type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

fieldset {
  padding: .35em .75em .625em;
}

legend {
  box-sizing: border-box;
  color: inherit;
  max-width: 100%;
  white-space: normal;
  padding: 0;
  display: table;
}

progress {
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type="checkbox"], [type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

[type="number"]::-webkit-inner-spin-button, [type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

details {
  display: block;
}

summary {
  display: list-item;
}

template, [hidden] {
  display: none;
}

html, body, div, article, aside, section, main, nav, footer, header, form, fieldset, legend, pre, code, a, h1, h2, h3, h4, h5, h6, p, ul, ol, li, dl, dt, dd, blockquote, figcaption, figure, textarea, table, td, th, tr, input[type="email"], input[type="number"], input[type="password"], input[type="tel"], input[type="text"], input[type="url"], .border-box {
  box-sizing: border-box;
}

.aspect-ratio {
  height: 0;
  position: relative;
}

.aspect-ratio--16x9 {
  padding-bottom: 56.25%;
}

.aspect-ratio--9x16 {
  padding-bottom: 177.77%;
}

.aspect-ratio--4x3 {
  padding-bottom: 75%;
}

.aspect-ratio--3x4 {
  padding-bottom: 133.33%;
}

.aspect-ratio--6x4 {
  padding-bottom: 66.6%;
}

.aspect-ratio--4x6 {
  padding-bottom: 150%;
}

.aspect-ratio--8x5 {
  padding-bottom: 62.5%;
}

.aspect-ratio--5x8 {
  padding-bottom: 160%;
}

.aspect-ratio--7x5 {
  padding-bottom: 71.42%;
}

.aspect-ratio--5x7 {
  padding-bottom: 140%;
}

.aspect-ratio--1x1 {
  padding-bottom: 100%;
}

.aspect-ratio--object {
  width: 100%;
  height: 100%;
  z-index: 100;
  position: absolute;
  inset: 0;
}

img {
  max-width: 100%;
}

.cover {
  background-size: cover !important;
}

.contain {
  background-size: contain !important;
}

.bg-center {
  background-position: center;
  background-repeat: no-repeat;
}

.bg-top {
  background-position: top;
  background-repeat: no-repeat;
}

.bg-right {
  background-position: 100%;
  background-repeat: no-repeat;
}

.bg-bottom {
  background-position: bottom;
  background-repeat: no-repeat;
}

.bg-left {
  background-position: 0;
  background-repeat: no-repeat;
}

.outline {
  outline: 1px solid;
}

.outline-transparent {
  outline: 1px solid #0000;
}

.outline-0 {
  outline: 0;
}

.ba {
  border-style: solid;
  border-width: 1px;
}

.bt {
  border-top-style: solid;
  border-top-width: 1px;
}

.br {
  border-right-style: solid;
  border-right-width: 1px;
}

.bb {
  border-bottom-style: solid;
  border-bottom-width: 1px;
}

.bl {
  border-left-style: solid;
  border-left-width: 1px;
}

.bn {
  border-style: none;
  border-width: 0;
}

.b--black {
  border-color: #000;
}

.b--near-black {
  border-color: #111;
}

.b--dark-gray {
  border-color: #333;
}

.b--mid-gray {
  border-color: #555;
}

.b--gray {
  border-color: #777;
}

.b--silver {
  border-color: #999;
}

.b--light-silver {
  border-color: #aaa;
}

.b--moon-gray {
  border-color: #ccc;
}

.b--light-gray {
  border-color: #eee;
}

.b--near-white {
  border-color: #f4f4f4;
}

.b--white {
  border-color: #fff;
}

.b--white-90 {
  border-color: #ffffffe6;
}

.b--white-80 {
  border-color: #fffc;
}

.b--white-70 {
  border-color: #ffffffb3;
}

.b--white-60 {
  border-color: #fff9;
}

.b--white-50 {
  border-color: #ffffff80;
}

.b--white-40 {
  border-color: #fff6;
}

.b--white-30 {
  border-color: #ffffff4d;
}

.b--white-20 {
  border-color: #fff3;
}

.b--white-10 {
  border-color: #ffffff1a;
}

.b--white-05 {
  border-color: #ffffff0d;
}

.b--white-025 {
  border-color: #ffffff06;
}

.b--white-0125 {
  border-color: #ffffff03;
}

.b--black-90 {
  border-color: #000000e6;
}

.b--black-80 {
  border-color: #000c;
}

.b--black-70 {
  border-color: #000000b3;
}

.b--black-60 {
  border-color: #0009;
}

.b--black-50 {
  border-color: #00000080;
}

.b--black-40 {
  border-color: #0006;
}

.b--black-30 {
  border-color: #0000004d;
}

.b--black-20 {
  border-color: #0003;
}

.b--black-10 {
  border-color: #0000001a;
}

.b--black-05 {
  border-color: #0000000d;
}

.b--black-025 {
  border-color: #00000006;
}

.b--black-0125 {
  border-color: #00000003;
}

.b--dark-red {
  border-color: #e7040f;
}

.b--red {
  border-color: #ff4136;
}

.b--light-red {
  border-color: #ff725c;
}

.b--orange {
  border-color: #ff6300;
}

.b--gold {
  border-color: #ffb700;
}

.b--yellow {
  border-color: gold;
}

.b--light-yellow {
  border-color: #fbf1a9;
}

.b--purple {
  border-color: #5e2ca5;
}

.b--light-purple {
  border-color: #a463f2;
}

.b--dark-pink {
  border-color: #d5008f;
}

.b--hot-pink {
  border-color: #ff41b4;
}

.b--pink {
  border-color: #ff80cc;
}

.b--light-pink {
  border-color: #ffa3d7;
}

.b--dark-green {
  border-color: #137752;
}

.b--green {
  border-color: #19a974;
}

.b--light-green {
  border-color: #9eebcf;
}

.b--navy {
  border-color: #001b44;
}

.b--dark-blue {
  border-color: #00449e;
}

.b--blue {
  border-color: #357edd;
}

.b--light-blue {
  border-color: #96ccff;
}

.b--lightest-blue {
  border-color: #cdecff;
}

.b--washed-blue {
  border-color: #f6fffe;
}

.b--washed-green {
  border-color: #e8fdf5;
}

.b--washed-yellow {
  border-color: #fffceb;
}

.b--washed-red {
  border-color: #ffdfdf;
}

.b--transparent {
  border-color: #0000;
}

.b--inherit {
  border-color: inherit;
}

.b--initial {
  border-color: initial;
}

.b--unset {
  border-color: unset;
}

.br0 {
  border-radius: 0;
}

.br1 {
  border-radius: .125rem;
}

.br2 {
  border-radius: .25rem;
}

.br3 {
  border-radius: .5rem;
}

.br4 {
  border-radius: 1rem;
}

.br-100 {
  border-radius: 100%;
}

.br-pill {
  border-radius: 9999px;
}

.br--bottom {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.br--top {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.br--right {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.br--left {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.br-inherit {
  border-radius: inherit;
}

.br-initial {
  border-radius: initial;
}

.br-unset {
  border-radius: unset;
}

.b--dotted {
  border-style: dotted;
}

.b--dashed {
  border-style: dashed;
}

.b--solid {
  border-style: solid;
}

.b--none {
  border-style: none;
}

.bw0 {
  border-width: 0;
}

.bw1 {
  border-width: .125rem;
}

.bw2 {
  border-width: .25rem;
}

.bw3 {
  border-width: .5rem;
}

.bw4 {
  border-width: 1rem;
}

.bw5 {
  border-width: 2rem;
}

.bt-0 {
  border-top-width: 0;
}

.br-0 {
  border-right-width: 0;
}

.bb-0 {
  border-bottom-width: 0;
}

.bl-0 {
  border-left-width: 0;
}

.shadow-1 {
  box-shadow: 0 0 4px 2px #0003;
}

.shadow-2 {
  box-shadow: 0 0 8px 2px #0003;
}

.shadow-3 {
  box-shadow: 2px 2px 4px 2px #0003;
}

.shadow-4 {
  box-shadow: 2px 2px 8px #0003;
}

.shadow-5 {
  box-shadow: 4px 4px 8px #0003;
}

.pre {
  overflow: scroll;
}

.top-0 {
  top: 0;
}

.right-0 {
  right: 0;
}

.bottom-0 {
  bottom: 0;
}

.left-0 {
  left: 0;
}

.top-1 {
  top: 1rem;
}

.right-1 {
  right: 1rem;
}

.bottom-1 {
  bottom: 1rem;
}

.left-1 {
  left: 1rem;
}

.top-2 {
  top: 2rem;
}

.right-2 {
  right: 2rem;
}

.bottom-2 {
  bottom: 2rem;
}

.left-2 {
  left: 2rem;
}

.top--1 {
  top: -1rem;
}

.right--1 {
  right: -1rem;
}

.bottom--1 {
  bottom: -1rem;
}

.left--1 {
  left: -1rem;
}

.top--2 {
  top: -2rem;
}

.right--2 {
  right: -2rem;
}

.bottom--2 {
  bottom: -2rem;
}

.left--2 {
  left: -2rem;
}

.absolute--fill {
  inset: 0;
}

.cf:before, .cf:after {
  content: " ";
  display: table;
}

.cf:after {
  clear: both;
}

.cf {
  zoom: 1;
}

.cl {
  clear: left;
}

.cr {
  clear: right;
}

.cb {
  clear: both;
}

.cn {
  clear: none;
}

.dn {
  display: none;
}

.di {
  display: inline;
}

.db {
  display: block;
}

.dib {
  display: inline-block;
}

.dit {
  display: inline-table;
}

.dt {
  display: table;
}

.dtc {
  display: table-cell;
}

.dt-row {
  display: table-row;
}

.dt-row-group {
  display: table-row-group;
}

.dt-column {
  display: table-column;
}

.dt-column-group {
  display: table-column-group;
}

.dt--fixed {
  table-layout: fixed;
  width: 100%;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.flex-auto {
  min-width: 0;
  min-height: 0;
  flex: auto;
}

.flex-none {
  flex: none;
}

.flex-column {
  flex-direction: column;
}

.flex-row {
  flex-direction: row;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse;
}

.flex-column-reverse {
  flex-direction: column-reverse;
}

.flex-row-reverse {
  flex-direction: row-reverse;
}

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}

.items-baseline {
  align-items: baseline;
}

.items-stretch {
  align-items: stretch;
}

.self-start {
  align-self: flex-start;
}

.self-end {
  align-self: flex-end;
}

.self-center {
  align-self: center;
}

.self-baseline {
  align-self: baseline;
}

.self-stretch {
  align-self: stretch;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.content-start {
  align-content: flex-start;
}

.content-end {
  align-content: flex-end;
}

.content-center {
  align-content: center;
}

.content-between {
  align-content: space-between;
}

.content-around {
  align-content: space-around;
}

.content-stretch {
  align-content: stretch;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-last {
  order: 99999;
}

.flex-grow-0 {
  flex-grow: 0;
}

.flex-grow-1 {
  flex-grow: 1;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.flex-shrink-1 {
  flex-shrink: 1;
}

.fl {
  float: left;
  _display: inline;
}

.fr {
  float: right;
  _display: inline;
}

.fn {
  float: none;
}

.sans-serif {
  font-family: -apple-system, BlinkMacSystemFont, avenir next, avenir, helvetica neue, helvetica, ubuntu, roboto, noto, segoe ui, arial, sans-serif;
}

.serif {
  font-family: georgia, times, serif;
}

.system-sans-serif {
  font-family: sans-serif;
}

.system-serif {
  font-family: serif;
}

code, .code {
  font-family: Consolas, monaco, monospace;
}

.courier {
  font-family: Courier Next, courier, monospace;
}

.helvetica {
  font-family: helvetica neue, helvetica, sans-serif;
}

.avenir {
  font-family: avenir next, avenir, sans-serif;
}

.athelas {
  font-family: athelas, georgia, serif;
}

.georgia {
  font-family: georgia, serif;
}

.times {
  font-family: times, serif;
}

.bodoni {
  font-family: Bodoni MT, serif;
}

.calisto {
  font-family: Calisto MT, serif;
}

.garamond {
  font-family: garamond, serif;
}

.baskerville {
  font-family: baskerville, serif;
}

.i {
  font-style: italic;
}

.fs-normal {
  font-style: normal;
}

.normal {
  font-weight: normal;
}

.b {
  font-weight: bold;
}

.fw1 {
  font-weight: 100;
}

.fw2 {
  font-weight: 200;
}

.fw3 {
  font-weight: 300;
}

.fw4 {
  font-weight: 400;
}

.fw5 {
  font-weight: 500;
}

.fw6 {
  font-weight: 600;
}

.fw7 {
  font-weight: 700;
}

.fw8 {
  font-weight: 800;
}

.fw9 {
  font-weight: 900;
}

.input-reset {
  -webkit-appearance: none;
  -moz-appearance: none;
}

.button-reset::-moz-focus-inner, .input-reset::-moz-focus-inner {
  border: 0;
  padding: 0;
}

.h1 {
  height: 1rem;
}

.h2 {
  height: 2rem;
}

.h3 {
  height: 4rem;
}

.h4 {
  height: 8rem;
}

.h5 {
  height: 16rem;
}

.h-25 {
  height: 25%;
}

.h-50 {
  height: 50%;
}

.h-75 {
  height: 75%;
}

.h-100 {
  height: 100%;
}

.min-h-100 {
  min-height: 100%;
}

.vh-25 {
  height: 25vh;
}

.vh-50 {
  height: 50vh;
}

.vh-75 {
  height: 75vh;
}

.vh-100 {
  height: 100vh;
}

.min-vh-100 {
  min-height: 100vh;
}

.h-auto {
  height: auto;
}

.h-inherit {
  height: inherit;
}

.tracked {
  letter-spacing: .1em;
}

.tracked-tight {
  letter-spacing: -.05em;
}

.tracked-mega {
  letter-spacing: .25em;
}

.lh-solid {
  line-height: 1;
}

.lh-title {
  line-height: 1.25;
}

.lh-copy {
  line-height: 1.5;
}

.link {
  text-decoration: none;
  transition: color .15s ease-in;
}

.link:link, .link:visited, .link:hover, .link:active {
  transition: color .15s ease-in;
}

.link:focus {
  outline: 1px dotted;
  transition: color .15s ease-in;
}

.list {
  list-style-type: none;
}

.mw-100 {
  max-width: 100%;
}

.mw1 {
  max-width: 1rem;
}

.mw2 {
  max-width: 2rem;
}

.mw3 {
  max-width: 4rem;
}

.mw4 {
  max-width: 8rem;
}

.mw5 {
  max-width: 16rem;
}

.mw6 {
  max-width: 32rem;
}

.mw7 {
  max-width: 48rem;
}

.mw8 {
  max-width: 64rem;
}

.mw9 {
  max-width: 96rem;
}

.mw-none {
  max-width: none;
}

.w1 {
  width: 1rem;
}

.w2 {
  width: 2rem;
}

.w3 {
  width: 4rem;
}

.w4 {
  width: 8rem;
}

.w5 {
  width: 16rem;
}

.w-10 {
  width: 10%;
}

.w-20 {
  width: 20%;
}

.w-25 {
  width: 25%;
}

.w-30 {
  width: 30%;
}

.w-33 {
  width: 33%;
}

.w-34 {
  width: 34%;
}

.w-40 {
  width: 40%;
}

.w-50 {
  width: 50%;
}

.w-60 {
  width: 60%;
}

.w-70 {
  width: 70%;
}

.w-75 {
  width: 75%;
}

.w-80 {
  width: 80%;
}

.w-90 {
  width: 90%;
}

.w-100 {
  width: 100%;
}

.w-third {
  width: 33.3333%;
}

.w-two-thirds {
  width: 66.6667%;
}

.w-auto {
  width: auto;
}

.overflow-visible {
  overflow: visible;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-scroll {
  overflow: scroll;
}

.overflow-auto {
  overflow: auto;
}

.overflow-x-visible {
  overflow-x: visible;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.overflow-x-scroll {
  overflow-x: scroll;
}

.overflow-x-auto {
  overflow-x: auto;
}

.overflow-y-visible {
  overflow-y: visible;
}

.overflow-y-hidden {
  overflow-y: hidden;
}

.overflow-y-scroll {
  overflow-y: scroll;
}

.overflow-y-auto {
  overflow-y: auto;
}

.static {
  position: static;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.fixed {
  position: fixed;
}

.o-100 {
  opacity: 1;
}

.o-90 {
  opacity: .9;
}

.o-80 {
  opacity: .8;
}

.o-70 {
  opacity: .7;
}

.o-60 {
  opacity: .6;
}

.o-50 {
  opacity: .5;
}

.o-40 {
  opacity: .4;
}

.o-30 {
  opacity: .3;
}

.o-20 {
  opacity: .2;
}

.o-10 {
  opacity: .1;
}

.o-05 {
  opacity: .05;
}

.o-025 {
  opacity: .025;
}

.o-0 {
  opacity: 0;
}

.rotate-45 {
  transform: rotate(45deg);
}

.rotate-90 {
  transform: rotate(90deg);
}

.rotate-135 {
  transform: rotate(135deg);
}

.rotate-180 {
  transform: rotate(180deg);
}

.rotate-225 {
  transform: rotate(225deg);
}

.rotate-270 {
  transform: rotate(270deg);
}

.rotate-315 {
  transform: rotate(315deg);
}

.black-90 {
  color: #000000e6;
}

.black-80 {
  color: #000c;
}

.black-70 {
  color: #000000b3;
}

.black-60 {
  color: #0009;
}

.black-50 {
  color: #00000080;
}

.black-40 {
  color: #0006;
}

.black-30 {
  color: #0000004d;
}

.black-20 {
  color: #0003;
}

.black-10 {
  color: #0000001a;
}

.black-05 {
  color: #0000000d;
}

.white-90 {
  color: #ffffffe6;
}

.white-80 {
  color: #fffc;
}

.white-70 {
  color: #ffffffb3;
}

.white-60 {
  color: #fff9;
}

.white-50 {
  color: #ffffff80;
}

.white-40 {
  color: #fff6;
}

.white-30 {
  color: #ffffff4d;
}

.white-20 {
  color: #fff3;
}

.white-10 {
  color: #ffffff1a;
}

.black {
  color: #000;
}

.near-black {
  color: #111;
}

.dark-gray {
  color: #333;
}

.mid-gray {
  color: #555;
}

.gray {
  color: #777;
}

.silver {
  color: #999;
}

.light-silver {
  color: #aaa;
}

.moon-gray {
  color: #ccc;
}

.light-gray {
  color: #eee;
}

.near-white {
  color: #f4f4f4;
}

.white {
  color: #fff;
}

.dark-red {
  color: #e7040f;
}

.red {
  color: #ff4136;
}

.light-red {
  color: #ff725c;
}

.orange {
  color: #ff6300;
}

.gold {
  color: #ffb700;
}

.yellow {
  color: gold;
}

.light-yellow {
  color: #fbf1a9;
}

.purple {
  color: #5e2ca5;
}

.light-purple {
  color: #a463f2;
}

.dark-pink {
  color: #d5008f;
}

.hot-pink {
  color: #ff41b4;
}

.pink {
  color: #ff80cc;
}

.light-pink {
  color: #ffa3d7;
}

.dark-green {
  color: #137752;
}

.green {
  color: #19a974;
}

.light-green {
  color: #9eebcf;
}

.navy {
  color: #001b44;
}

.dark-blue {
  color: #00449e;
}

.blue {
  color: #357edd;
}

.light-blue {
  color: #96ccff;
}

.lightest-blue {
  color: #cdecff;
}

.washed-blue {
  color: #f6fffe;
}

.washed-green {
  color: #e8fdf5;
}

.washed-yellow {
  color: #fffceb;
}

.washed-red {
  color: #ffdfdf;
}

.color-inherit {
  color: inherit;
}

.bg-black-90 {
  background-color: #000000e6;
}

.bg-black-80 {
  background-color: #000c;
}

.bg-black-70 {
  background-color: #000000b3;
}

.bg-black-60 {
  background-color: #0009;
}

.bg-black-50 {
  background-color: #00000080;
}

.bg-black-40 {
  background-color: #0006;
}

.bg-black-30 {
  background-color: #0000004d;
}

.bg-black-20 {
  background-color: #0003;
}

.bg-black-10 {
  background-color: #0000001a;
}

.bg-black-05 {
  background-color: #0000000d;
}

.bg-white-90 {
  background-color: #ffffffe6;
}

.bg-white-80 {
  background-color: #fffc;
}

.bg-white-70 {
  background-color: #ffffffb3;
}

.bg-white-60 {
  background-color: #fff9;
}

.bg-white-50 {
  background-color: #ffffff80;
}

.bg-white-40 {
  background-color: #fff6;
}

.bg-white-30 {
  background-color: #ffffff4d;
}

.bg-white-20 {
  background-color: #fff3;
}

.bg-white-10 {
  background-color: #ffffff1a;
}

.bg-black {
  background-color: #000;
}

.bg-near-black {
  background-color: #111;
}

.bg-dark-gray {
  background-color: #333;
}

.bg-mid-gray {
  background-color: #555;
}

.bg-gray {
  background-color: #777;
}

.bg-silver {
  background-color: #999;
}

.bg-light-silver {
  background-color: #aaa;
}

.bg-moon-gray {
  background-color: #ccc;
}

.bg-light-gray {
  background-color: #eee;
}

.bg-near-white {
  background-color: #f4f4f4;
}

.bg-white {
  background-color: #fff;
}

.bg-transparent {
  background-color: #0000;
}

.bg-dark-red {
  background-color: #e7040f;
}

.bg-red {
  background-color: #ff4136;
}

.bg-light-red {
  background-color: #ff725c;
}

.bg-orange {
  background-color: #ff6300;
}

.bg-gold {
  background-color: #ffb700;
}

.bg-yellow {
  background-color: gold;
}

.bg-light-yellow {
  background-color: #fbf1a9;
}

.bg-purple {
  background-color: #5e2ca5;
}

.bg-light-purple {
  background-color: #a463f2;
}

.bg-dark-pink {
  background-color: #d5008f;
}

.bg-hot-pink {
  background-color: #ff41b4;
}

.bg-pink {
  background-color: #ff80cc;
}

.bg-light-pink {
  background-color: #ffa3d7;
}

.bg-dark-green {
  background-color: #137752;
}

.bg-green {
  background-color: #19a974;
}

.bg-light-green {
  background-color: #9eebcf;
}

.bg-navy {
  background-color: #001b44;
}

.bg-dark-blue {
  background-color: #00449e;
}

.bg-blue {
  background-color: #357edd;
}

.bg-light-blue {
  background-color: #96ccff;
}

.bg-lightest-blue {
  background-color: #cdecff;
}

.bg-washed-blue {
  background-color: #f6fffe;
}

.bg-washed-green {
  background-color: #e8fdf5;
}

.bg-washed-yellow {
  background-color: #fffceb;
}

.bg-washed-red {
  background-color: #ffdfdf;
}

.bg-inherit {
  background-color: inherit;
}

.hover-black:hover, .hover-black:focus {
  color: #000;
}

.hover-near-black:hover, .hover-near-black:focus {
  color: #111;
}

.hover-dark-gray:hover, .hover-dark-gray:focus {
  color: #333;
}

.hover-mid-gray:hover, .hover-mid-gray:focus {
  color: #555;
}

.hover-gray:hover, .hover-gray:focus {
  color: #777;
}

.hover-silver:hover, .hover-silver:focus {
  color: #999;
}

.hover-light-silver:hover, .hover-light-silver:focus {
  color: #aaa;
}

.hover-moon-gray:hover, .hover-moon-gray:focus {
  color: #ccc;
}

.hover-light-gray:hover, .hover-light-gray:focus {
  color: #eee;
}

.hover-near-white:hover, .hover-near-white:focus {
  color: #f4f4f4;
}

.hover-white:hover, .hover-white:focus {
  color: #fff;
}

.hover-black-90:hover, .hover-black-90:focus {
  color: #000000e6;
}

.hover-black-80:hover, .hover-black-80:focus {
  color: #000c;
}

.hover-black-70:hover, .hover-black-70:focus {
  color: #000000b3;
}

.hover-black-60:hover, .hover-black-60:focus {
  color: #0009;
}

.hover-black-50:hover, .hover-black-50:focus {
  color: #00000080;
}

.hover-black-40:hover, .hover-black-40:focus {
  color: #0006;
}

.hover-black-30:hover, .hover-black-30:focus {
  color: #0000004d;
}

.hover-black-20:hover, .hover-black-20:focus {
  color: #0003;
}

.hover-black-10:hover, .hover-black-10:focus {
  color: #0000001a;
}

.hover-white-90:hover, .hover-white-90:focus {
  color: #ffffffe6;
}

.hover-white-80:hover, .hover-white-80:focus {
  color: #fffc;
}

.hover-white-70:hover, .hover-white-70:focus {
  color: #ffffffb3;
}

.hover-white-60:hover, .hover-white-60:focus {
  color: #fff9;
}

.hover-white-50:hover, .hover-white-50:focus {
  color: #ffffff80;
}

.hover-white-40:hover, .hover-white-40:focus {
  color: #fff6;
}

.hover-white-30:hover, .hover-white-30:focus {
  color: #ffffff4d;
}

.hover-white-20:hover, .hover-white-20:focus {
  color: #fff3;
}

.hover-white-10:hover, .hover-white-10:focus {
  color: #ffffff1a;
}

.hover-inherit:hover, .hover-inherit:focus {
  color: inherit;
}

.hover-bg-black:hover, .hover-bg-black:focus {
  background-color: #000;
}

.hover-bg-near-black:hover, .hover-bg-near-black:focus {
  background-color: #111;
}

.hover-bg-dark-gray:hover, .hover-bg-dark-gray:focus {
  background-color: #333;
}

.hover-bg-mid-gray:hover, .hover-bg-mid-gray:focus {
  background-color: #555;
}

.hover-bg-gray:hover, .hover-bg-gray:focus {
  background-color: #777;
}

.hover-bg-silver:hover, .hover-bg-silver:focus {
  background-color: #999;
}

.hover-bg-light-silver:hover, .hover-bg-light-silver:focus {
  background-color: #aaa;
}

.hover-bg-moon-gray:hover, .hover-bg-moon-gray:focus {
  background-color: #ccc;
}

.hover-bg-light-gray:hover, .hover-bg-light-gray:focus {
  background-color: #eee;
}

.hover-bg-near-white:hover, .hover-bg-near-white:focus {
  background-color: #f4f4f4;
}

.hover-bg-white:hover, .hover-bg-white:focus {
  background-color: #fff;
}

.hover-bg-transparent:hover, .hover-bg-transparent:focus {
  background-color: #0000;
}

.hover-bg-black-90:hover, .hover-bg-black-90:focus {
  background-color: #000000e6;
}

.hover-bg-black-80:hover, .hover-bg-black-80:focus {
  background-color: #000c;
}

.hover-bg-black-70:hover, .hover-bg-black-70:focus {
  background-color: #000000b3;
}

.hover-bg-black-60:hover, .hover-bg-black-60:focus {
  background-color: #0009;
}

.hover-bg-black-50:hover, .hover-bg-black-50:focus {
  background-color: #00000080;
}

.hover-bg-black-40:hover, .hover-bg-black-40:focus {
  background-color: #0006;
}

.hover-bg-black-30:hover, .hover-bg-black-30:focus {
  background-color: #0000004d;
}

.hover-bg-black-20:hover, .hover-bg-black-20:focus {
  background-color: #0003;
}

.hover-bg-black-10:hover, .hover-bg-black-10:focus {
  background-color: #0000001a;
}

.hover-bg-white-90:hover, .hover-bg-white-90:focus {
  background-color: #ffffffe6;
}

.hover-bg-white-80:hover, .hover-bg-white-80:focus {
  background-color: #fffc;
}

.hover-bg-white-70:hover, .hover-bg-white-70:focus {
  background-color: #ffffffb3;
}

.hover-bg-white-60:hover, .hover-bg-white-60:focus {
  background-color: #fff9;
}

.hover-bg-white-50:hover, .hover-bg-white-50:focus {
  background-color: #ffffff80;
}

.hover-bg-white-40:hover, .hover-bg-white-40:focus {
  background-color: #fff6;
}

.hover-bg-white-30:hover, .hover-bg-white-30:focus {
  background-color: #ffffff4d;
}

.hover-bg-white-20:hover, .hover-bg-white-20:focus {
  background-color: #fff3;
}

.hover-bg-white-10:hover, .hover-bg-white-10:focus {
  background-color: #ffffff1a;
}

.hover-dark-red:hover, .hover-dark-red:focus {
  color: #e7040f;
}

.hover-red:hover, .hover-red:focus {
  color: #ff4136;
}

.hover-light-red:hover, .hover-light-red:focus {
  color: #ff725c;
}

.hover-orange:hover, .hover-orange:focus {
  color: #ff6300;
}

.hover-gold:hover, .hover-gold:focus {
  color: #ffb700;
}

.hover-yellow:hover, .hover-yellow:focus {
  color: gold;
}

.hover-light-yellow:hover, .hover-light-yellow:focus {
  color: #fbf1a9;
}

.hover-purple:hover, .hover-purple:focus {
  color: #5e2ca5;
}

.hover-light-purple:hover, .hover-light-purple:focus {
  color: #a463f2;
}

.hover-dark-pink:hover, .hover-dark-pink:focus {
  color: #d5008f;
}

.hover-hot-pink:hover, .hover-hot-pink:focus {
  color: #ff41b4;
}

.hover-pink:hover, .hover-pink:focus {
  color: #ff80cc;
}

.hover-light-pink:hover, .hover-light-pink:focus {
  color: #ffa3d7;
}

.hover-dark-green:hover, .hover-dark-green:focus {
  color: #137752;
}

.hover-green:hover, .hover-green:focus {
  color: #19a974;
}

.hover-light-green:hover, .hover-light-green:focus {
  color: #9eebcf;
}

.hover-navy:hover, .hover-navy:focus {
  color: #001b44;
}

.hover-dark-blue:hover, .hover-dark-blue:focus {
  color: #00449e;
}

.hover-blue:hover, .hover-blue:focus {
  color: #357edd;
}

.hover-light-blue:hover, .hover-light-blue:focus {
  color: #96ccff;
}

.hover-lightest-blue:hover, .hover-lightest-blue:focus {
  color: #cdecff;
}

.hover-washed-blue:hover, .hover-washed-blue:focus {
  color: #f6fffe;
}

.hover-washed-green:hover, .hover-washed-green:focus {
  color: #e8fdf5;
}

.hover-washed-yellow:hover, .hover-washed-yellow:focus {
  color: #fffceb;
}

.hover-washed-red:hover, .hover-washed-red:focus {
  color: #ffdfdf;
}

.hover-bg-dark-red:hover, .hover-bg-dark-red:focus {
  background-color: #e7040f;
}

.hover-bg-red:hover, .hover-bg-red:focus {
  background-color: #ff4136;
}

.hover-bg-light-red:hover, .hover-bg-light-red:focus {
  background-color: #ff725c;
}

.hover-bg-orange:hover, .hover-bg-orange:focus {
  background-color: #ff6300;
}

.hover-bg-gold:hover, .hover-bg-gold:focus {
  background-color: #ffb700;
}

.hover-bg-yellow:hover, .hover-bg-yellow:focus {
  background-color: gold;
}

.hover-bg-light-yellow:hover, .hover-bg-light-yellow:focus {
  background-color: #fbf1a9;
}

.hover-bg-purple:hover, .hover-bg-purple:focus {
  background-color: #5e2ca5;
}

.hover-bg-light-purple:hover, .hover-bg-light-purple:focus {
  background-color: #a463f2;
}

.hover-bg-dark-pink:hover, .hover-bg-dark-pink:focus {
  background-color: #d5008f;
}

.hover-bg-hot-pink:hover, .hover-bg-hot-pink:focus {
  background-color: #ff41b4;
}

.hover-bg-pink:hover, .hover-bg-pink:focus {
  background-color: #ff80cc;
}

.hover-bg-light-pink:hover, .hover-bg-light-pink:focus {
  background-color: #ffa3d7;
}

.hover-bg-dark-green:hover, .hover-bg-dark-green:focus {
  background-color: #137752;
}

.hover-bg-green:hover, .hover-bg-green:focus {
  background-color: #19a974;
}

.hover-bg-light-green:hover, .hover-bg-light-green:focus {
  background-color: #9eebcf;
}

.hover-bg-navy:hover, .hover-bg-navy:focus {
  background-color: #001b44;
}

.hover-bg-dark-blue:hover, .hover-bg-dark-blue:focus {
  background-color: #00449e;
}

.hover-bg-blue:hover, .hover-bg-blue:focus {
  background-color: #357edd;
}

.hover-bg-light-blue:hover, .hover-bg-light-blue:focus {
  background-color: #96ccff;
}

.hover-bg-lightest-blue:hover, .hover-bg-lightest-blue:focus {
  background-color: #cdecff;
}

.hover-bg-washed-blue:hover, .hover-bg-washed-blue:focus {
  background-color: #f6fffe;
}

.hover-bg-washed-green:hover, .hover-bg-washed-green:focus {
  background-color: #e8fdf5;
}

.hover-bg-washed-yellow:hover, .hover-bg-washed-yellow:focus {
  background-color: #fffceb;
}

.hover-bg-washed-red:hover, .hover-bg-washed-red:focus {
  background-color: #ffdfdf;
}

.hover-bg-inherit:hover, .hover-bg-inherit:focus {
  background-color: inherit;
}

.pa0 {
  padding: 0;
}

.pa1 {
  padding: .25rem;
}

.pa2 {
  padding: .5rem;
}

.pa3 {
  padding: 1rem;
}

.pa4 {
  padding: 2rem;
}

.pa5 {
  padding: 4rem;
}

.pa6 {
  padding: 8rem;
}

.pa7 {
  padding: 16rem;
}

.pl0 {
  padding-left: 0;
}

.pl1 {
  padding-left: .25rem;
}

.pl2 {
  padding-left: .5rem;
}

.pl3 {
  padding-left: 1rem;
}

.pl4 {
  padding-left: 2rem;
}

.pl5 {
  padding-left: 4rem;
}

.pl6 {
  padding-left: 8rem;
}

.pl7 {
  padding-left: 16rem;
}

.pr0 {
  padding-right: 0;
}

.pr1 {
  padding-right: .25rem;
}

.pr2 {
  padding-right: .5rem;
}

.pr3 {
  padding-right: 1rem;
}

.pr4 {
  padding-right: 2rem;
}

.pr5 {
  padding-right: 4rem;
}

.pr6 {
  padding-right: 8rem;
}

.pr7 {
  padding-right: 16rem;
}

.pb0 {
  padding-bottom: 0;
}

.pb1 {
  padding-bottom: .25rem;
}

.pb2 {
  padding-bottom: .5rem;
}

.pb3 {
  padding-bottom: 1rem;
}

.pb4 {
  padding-bottom: 2rem;
}

.pb5 {
  padding-bottom: 4rem;
}

.pb6 {
  padding-bottom: 8rem;
}

.pb7 {
  padding-bottom: 16rem;
}

.pt0 {
  padding-top: 0;
}

.pt1 {
  padding-top: .25rem;
}

.pt2 {
  padding-top: .5rem;
}

.pt3 {
  padding-top: 1rem;
}

.pt4 {
  padding-top: 2rem;
}

.pt5 {
  padding-top: 4rem;
}

.pt6 {
  padding-top: 8rem;
}

.pt7 {
  padding-top: 16rem;
}

.pv0 {
  padding-top: 0;
  padding-bottom: 0;
}

.pv1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.pv2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.pv3 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.pv4 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.pv5 {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.pv6 {
  padding-top: 8rem;
  padding-bottom: 8rem;
}

.pv7 {
  padding-top: 16rem;
  padding-bottom: 16rem;
}

.ph0 {
  padding-left: 0;
  padding-right: 0;
}

.ph1 {
  padding-left: .25rem;
  padding-right: .25rem;
}

.ph2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.ph3 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.ph4 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.ph5 {
  padding-left: 4rem;
  padding-right: 4rem;
}

.ph6 {
  padding-left: 8rem;
  padding-right: 8rem;
}

.ph7 {
  padding-left: 16rem;
  padding-right: 16rem;
}

.ma0 {
  margin: 0;
}

.ma1 {
  margin: .25rem;
}

.ma2 {
  margin: .5rem;
}

.ma3 {
  margin: 1rem;
}

.ma4 {
  margin: 2rem;
}

.ma5 {
  margin: 4rem;
}

.ma6 {
  margin: 8rem;
}

.ma7 {
  margin: 16rem;
}

.ml0 {
  margin-left: 0;
}

.ml1 {
  margin-left: .25rem;
}

.ml2 {
  margin-left: .5rem;
}

.ml3 {
  margin-left: 1rem;
}

.ml4 {
  margin-left: 2rem;
}

.ml5 {
  margin-left: 4rem;
}

.ml6 {
  margin-left: 8rem;
}

.ml7 {
  margin-left: 16rem;
}

.mr0 {
  margin-right: 0;
}

.mr1 {
  margin-right: .25rem;
}

.mr2 {
  margin-right: .5rem;
}

.mr3 {
  margin-right: 1rem;
}

.mr4 {
  margin-right: 2rem;
}

.mr5 {
  margin-right: 4rem;
}

.mr6 {
  margin-right: 8rem;
}

.mr7 {
  margin-right: 16rem;
}

.mb0 {
  margin-bottom: 0;
}

.mb1 {
  margin-bottom: .25rem;
}

.mb2 {
  margin-bottom: .5rem;
}

.mb3 {
  margin-bottom: 1rem;
}

.mb4 {
  margin-bottom: 2rem;
}

.mb5 {
  margin-bottom: 4rem;
}

.mb6 {
  margin-bottom: 8rem;
}

.mb7 {
  margin-bottom: 16rem;
}

.mt0 {
  margin-top: 0;
}

.mt1 {
  margin-top: .25rem;
}

.mt2 {
  margin-top: .5rem;
}

.mt3 {
  margin-top: 1rem;
}

.mt4 {
  margin-top: 2rem;
}

.mt5 {
  margin-top: 4rem;
}

.mt6 {
  margin-top: 8rem;
}

.mt7 {
  margin-top: 16rem;
}

.mv0 {
  margin-top: 0;
  margin-bottom: 0;
}

.mv1 {
  margin-top: .25rem;
  margin-bottom: .25rem;
}

.mv2 {
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.mv3 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.mv4 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.mv5 {
  margin-top: 4rem;
  margin-bottom: 4rem;
}

.mv6 {
  margin-top: 8rem;
  margin-bottom: 8rem;
}

.mv7 {
  margin-top: 16rem;
  margin-bottom: 16rem;
}

.mh0 {
  margin-left: 0;
  margin-right: 0;
}

.mh1 {
  margin-left: .25rem;
  margin-right: .25rem;
}

.mh2 {
  margin-left: .5rem;
  margin-right: .5rem;
}

.mh3 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.mh4 {
  margin-left: 2rem;
  margin-right: 2rem;
}

.mh5 {
  margin-left: 4rem;
  margin-right: 4rem;
}

.mh6 {
  margin-left: 8rem;
  margin-right: 8rem;
}

.mh7 {
  margin-left: 16rem;
  margin-right: 16rem;
}

.na1 {
  margin: -.25rem;
}

.na2 {
  margin: -.5rem;
}

.na3 {
  margin: -1rem;
}

.na4 {
  margin: -2rem;
}

.na5 {
  margin: -4rem;
}

.na6 {
  margin: -8rem;
}

.na7 {
  margin: -16rem;
}

.nl1 {
  margin-left: -.25rem;
}

.nl2 {
  margin-left: -.5rem;
}

.nl3 {
  margin-left: -1rem;
}

.nl4 {
  margin-left: -2rem;
}

.nl5 {
  margin-left: -4rem;
}

.nl6 {
  margin-left: -8rem;
}

.nl7 {
  margin-left: -16rem;
}

.nr1 {
  margin-right: -.25rem;
}

.nr2 {
  margin-right: -.5rem;
}

.nr3 {
  margin-right: -1rem;
}

.nr4 {
  margin-right: -2rem;
}

.nr5 {
  margin-right: -4rem;
}

.nr6 {
  margin-right: -8rem;
}

.nr7 {
  margin-right: -16rem;
}

.nb1 {
  margin-bottom: -.25rem;
}

.nb2 {
  margin-bottom: -.5rem;
}

.nb3 {
  margin-bottom: -1rem;
}

.nb4 {
  margin-bottom: -2rem;
}

.nb5 {
  margin-bottom: -4rem;
}

.nb6 {
  margin-bottom: -8rem;
}

.nb7 {
  margin-bottom: -16rem;
}

.nt1 {
  margin-top: -.25rem;
}

.nt2 {
  margin-top: -.5rem;
}

.nt3 {
  margin-top: -1rem;
}

.nt4 {
  margin-top: -2rem;
}

.nt5 {
  margin-top: -4rem;
}

.nt6 {
  margin-top: -8rem;
}

.nt7 {
  margin-top: -16rem;
}

.collapse {
  border-collapse: collapse;
  border-spacing: 0;
}

.striped--light-silver:nth-child(2n+1) {
  background-color: #aaa;
}

.striped--moon-gray:nth-child(2n+1) {
  background-color: #ccc;
}

.striped--light-gray:nth-child(2n+1) {
  background-color: #eee;
}

.striped--near-white:nth-child(2n+1) {
  background-color: #f4f4f4;
}

.stripe-light:nth-child(2n+1) {
  background-color: #ffffff1a;
}

.stripe-dark:nth-child(2n+1) {
  background-color: #0000001a;
}

.strike {
  text-decoration: line-through;
}

.underline {
  text-decoration: underline;
}

.no-underline {
  text-decoration: none;
}

.tl {
  text-align: left;
}

.tr {
  text-align: right;
}

.tc {
  text-align: center;
}

.tj {
  text-align: justify;
}

.ttc {
  text-transform: capitalize;
}

.ttl {
  text-transform: lowercase;
}

.ttu {
  text-transform: uppercase;
}

.ttn {
  text-transform: none;
}

.f-6, .f-headline {
  font-size: 6rem;
}

.f-5, .f-subheadline {
  font-size: 5rem;
}

.f1 {
  font-size: 3rem;
}

.f2 {
  font-size: 2.25rem;
}

.f3 {
  font-size: 1.5rem;
}

.f4 {
  font-size: 1.25rem;
}

.f5 {
  font-size: 1rem;
}

.f6 {
  font-size: .875rem;
}

.f7 {
  font-size: .75rem;
}

.measure {
  max-width: 30em;
}

.measure-wide {
  max-width: 34em;
}

.measure-narrow {
  max-width: 20em;
}

.indent {
  text-indent: 1em;
  margin-top: 0;
  margin-bottom: 0;
}

.small-caps {
  font-variant: small-caps;
}

.truncate {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.overflow-container {
  overflow-y: scroll;
}

.center {
  margin-left: auto;
  margin-right: auto;
}

.mr-auto {
  margin-right: auto;
}

.ml-auto {
  margin-left: auto;
}

.clip {
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  _position: absolute !important;
  position: fixed !important;
}

.ws-normal {
  white-space: normal;
}

.nowrap {
  white-space: nowrap;
}

.pre {
  white-space: pre;
}

.v-base {
  vertical-align: baseline;
}

.v-mid {
  vertical-align: middle;
}

.v-top {
  vertical-align: top;
}

.v-btm {
  vertical-align: bottom;
}

.dim {
  opacity: 1;
  transition: opacity .15s ease-in;
}

.dim:hover, .dim:focus {
  opacity: .5;
  transition: opacity .15s ease-in;
}

.dim:active {
  opacity: .8;
  transition: opacity .15s ease-out;
}

.glow {
  transition: opacity .15s ease-in;
}

.glow:hover, .glow:focus {
  opacity: 1;
  transition: opacity .15s ease-in;
}

.hide-child .child {
  opacity: 0;
  transition: opacity .15s ease-in;
}

.hide-child:hover .child, .hide-child:focus .child, .hide-child:active .child {
  opacity: 1;
  transition: opacity .15s ease-in;
}

.underline-hover:hover, .underline-hover:focus {
  text-decoration: underline;
}

.grow {
  -moz-osx-font-smoothing: grayscale;
  backface-visibility: hidden;
  transition: transform .25s ease-out, -webkit-transform .25s ease-out;
  transform: translateZ(0);
}

.grow:hover, .grow:focus {
  transform: scale(1.05);
}

.grow:active {
  transform: scale(.9);
}

.grow-large {
  -moz-osx-font-smoothing: grayscale;
  backface-visibility: hidden;
  transition: transform .25s ease-in-out, -webkit-transform .25s ease-in-out;
  transform: translateZ(0);
}

.grow-large:hover, .grow-large:focus {
  transform: scale(1.2);
}

.grow-large:active {
  transform: scale(.95);
}

.pointer:hover {
  cursor: pointer;
}

.shadow-hover {
  cursor: pointer;
  transition: all .5s cubic-bezier(.165, .84, .44, 1);
  position: relative;
}

.shadow-hover:after {
  content: "";
  border-radius: inherit;
  opacity: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  transition: opacity .5s cubic-bezier(.165, .84, .44, 1);
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: 0 0 16px 2px #0003;
}

.shadow-hover:hover:after, .shadow-hover:focus:after {
  opacity: 1;
}

.bg-animate, .bg-animate:hover, .bg-animate:focus {
  transition: background-color .15s ease-in-out;
}

.z-0 {
  z-index: 0;
}

.z-1 {
  z-index: 1;
}

.z-2 {
  z-index: 2;
}

.z-3 {
  z-index: 3;
}

.z-4 {
  z-index: 4;
}

.z-5 {
  z-index: 5;
}

.z-999 {
  z-index: 999;
}

.z-9999 {
  z-index: 9999;
}

.z-max {
  z-index: 2147483647;
}

.z-inherit {
  z-index: inherit;
}

.z-initial {
  z-index: initial;
}

.z-unset {
  z-index: unset;
}

.nested-copy-line-height p, .nested-copy-line-height ul, .nested-copy-line-height ol {
  line-height: 1.5;
}

.nested-headline-line-height h1, .nested-headline-line-height h2, .nested-headline-line-height h3, .nested-headline-line-height h4, .nested-headline-line-height h5, .nested-headline-line-height h6 {
  line-height: 1.25;
}

.nested-list-reset ul, .nested-list-reset ol {
  margin-left: 0;
  padding-left: 0;
  list-style-type: none;
}

.nested-copy-indent p + p {
  text-indent: 1em;
  margin-top: 0;
  margin-bottom: 0;
}

.nested-copy-separator p + p {
  margin-top: 1.5em;
}

.nested-img img {
  width: 100%;
  max-width: 100%;
  display: block;
}

.nested-links a {
  color: #357edd;
  transition: color .15s ease-in;
}

.nested-links a:hover, .nested-links a:focus {
  color: #96ccff;
  transition: color .15s ease-in;
}

.debug * {
  outline: 1px solid gold;
}

.debug-white * {
  outline: 1px solid #fff;
}

.debug-black * {
  outline: 1px solid #000;
}

.debug-grid {
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAICAYAAADED76LAAAAFElEQVR4AWPAC97/9x0eCsAEPgwAVLshdpENIxcAAAAASUVORK5CYII=") 0 0;
}

.debug-grid-16 {
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAMklEQVR4AWOgCLz/b0epAa6UGuBOqQHOQHLUgFEDnAbcBZ4UGwDOkiCnkIhdgNgNxAYAiYlD+8sEuo8AAAAASUVORK5CYII=") 0 0;
}

.debug-grid-8-solid {
  background: #fff url("data:image/gif;base64,R0lGODdhCAAIAPEAAADw/wDx/////wAAACwAAAAACAAIAAACDZQvgaeb/lxbAIKA8y0AOw==") 0 0;
}

.debug-grid-16-solid {
  background: #fff url("data:image/gif;base64,R0lGODdhEAAQAPEAAADw/wDx/xXy/////ywAAAAAEAAQAAACIZyPKckYDQFsb6ZqD85jZ2+BkwiRFKehhqQCQgDHcgwEBQA7") 0 0;
}

@media screen and (min-width: 30em) {
  .aspect-ratio-ns {
    height: 0;
    position: relative;
  }

  .aspect-ratio--16x9-ns {
    padding-bottom: 56.25%;
  }

  .aspect-ratio--9x16-ns {
    padding-bottom: 177.77%;
  }

  .aspect-ratio--4x3-ns {
    padding-bottom: 75%;
  }

  .aspect-ratio--3x4-ns {
    padding-bottom: 133.33%;
  }

  .aspect-ratio--6x4-ns {
    padding-bottom: 66.6%;
  }

  .aspect-ratio--4x6-ns {
    padding-bottom: 150%;
  }

  .aspect-ratio--8x5-ns {
    padding-bottom: 62.5%;
  }

  .aspect-ratio--5x8-ns {
    padding-bottom: 160%;
  }

  .aspect-ratio--7x5-ns {
    padding-bottom: 71.42%;
  }

  .aspect-ratio--5x7-ns {
    padding-bottom: 140%;
  }

  .aspect-ratio--1x1-ns {
    padding-bottom: 100%;
  }

  .aspect-ratio--object-ns {
    width: 100%;
    height: 100%;
    z-index: 100;
    position: absolute;
    inset: 0;
  }

  .cover-ns {
    background-size: cover !important;
  }

  .contain-ns {
    background-size: contain !important;
  }

  .bg-center-ns {
    background-position: center;
    background-repeat: no-repeat;
  }

  .bg-top-ns {
    background-position: top;
    background-repeat: no-repeat;
  }

  .bg-right-ns {
    background-position: 100%;
    background-repeat: no-repeat;
  }

  .bg-bottom-ns {
    background-position: bottom;
    background-repeat: no-repeat;
  }

  .bg-left-ns {
    background-position: 0;
    background-repeat: no-repeat;
  }

  .outline-ns {
    outline: 1px solid;
  }

  .outline-transparent-ns {
    outline: 1px solid #0000;
  }

  .outline-0-ns {
    outline: 0;
  }

  .ba-ns {
    border-style: solid;
    border-width: 1px;
  }

  .bt-ns {
    border-top-style: solid;
    border-top-width: 1px;
  }

  .br-ns {
    border-right-style: solid;
    border-right-width: 1px;
  }

  .bb-ns {
    border-bottom-style: solid;
    border-bottom-width: 1px;
  }

  .bl-ns {
    border-left-style: solid;
    border-left-width: 1px;
  }

  .bn-ns {
    border-style: none;
    border-width: 0;
  }

  .br0-ns {
    border-radius: 0;
  }

  .br1-ns {
    border-radius: .125rem;
  }

  .br2-ns {
    border-radius: .25rem;
  }

  .br3-ns {
    border-radius: .5rem;
  }

  .br4-ns {
    border-radius: 1rem;
  }

  .br-100-ns {
    border-radius: 100%;
  }

  .br-pill-ns {
    border-radius: 9999px;
  }

  .br--bottom-ns {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .br--top-ns {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .br--right-ns {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .br--left-ns {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .br-inherit-ns {
    border-radius: inherit;
  }

  .br-initial-ns {
    border-radius: initial;
  }

  .br-unset-ns {
    border-radius: unset;
  }

  .b--dotted-ns {
    border-style: dotted;
  }

  .b--dashed-ns {
    border-style: dashed;
  }

  .b--solid-ns {
    border-style: solid;
  }

  .b--none-ns {
    border-style: none;
  }

  .bw0-ns {
    border-width: 0;
  }

  .bw1-ns {
    border-width: .125rem;
  }

  .bw2-ns {
    border-width: .25rem;
  }

  .bw3-ns {
    border-width: .5rem;
  }

  .bw4-ns {
    border-width: 1rem;
  }

  .bw5-ns {
    border-width: 2rem;
  }

  .bt-0-ns {
    border-top-width: 0;
  }

  .br-0-ns {
    border-right-width: 0;
  }

  .bb-0-ns {
    border-bottom-width: 0;
  }

  .bl-0-ns {
    border-left-width: 0;
  }

  .shadow-1-ns {
    box-shadow: 0 0 4px 2px #0003;
  }

  .shadow-2-ns {
    box-shadow: 0 0 8px 2px #0003;
  }

  .shadow-3-ns {
    box-shadow: 2px 2px 4px 2px #0003;
  }

  .shadow-4-ns {
    box-shadow: 2px 2px 8px #0003;
  }

  .shadow-5-ns {
    box-shadow: 4px 4px 8px #0003;
  }

  .top-0-ns {
    top: 0;
  }

  .left-0-ns {
    left: 0;
  }

  .right-0-ns {
    right: 0;
  }

  .bottom-0-ns {
    bottom: 0;
  }

  .top-1-ns {
    top: 1rem;
  }

  .left-1-ns {
    left: 1rem;
  }

  .right-1-ns {
    right: 1rem;
  }

  .bottom-1-ns {
    bottom: 1rem;
  }

  .top-2-ns {
    top: 2rem;
  }

  .left-2-ns {
    left: 2rem;
  }

  .right-2-ns {
    right: 2rem;
  }

  .bottom-2-ns {
    bottom: 2rem;
  }

  .top--1-ns {
    top: -1rem;
  }

  .right--1-ns {
    right: -1rem;
  }

  .bottom--1-ns {
    bottom: -1rem;
  }

  .left--1-ns {
    left: -1rem;
  }

  .top--2-ns {
    top: -2rem;
  }

  .right--2-ns {
    right: -2rem;
  }

  .bottom--2-ns {
    bottom: -2rem;
  }

  .left--2-ns {
    left: -2rem;
  }

  .absolute--fill-ns {
    inset: 0;
  }

  .cl-ns {
    clear: left;
  }

  .cr-ns {
    clear: right;
  }

  .cb-ns {
    clear: both;
  }

  .cn-ns {
    clear: none;
  }

  .dn-ns {
    display: none;
  }

  .di-ns {
    display: inline;
  }

  .db-ns {
    display: block;
  }

  .dib-ns {
    display: inline-block;
  }

  .dit-ns {
    display: inline-table;
  }

  .dt-ns {
    display: table;
  }

  .dtc-ns {
    display: table-cell;
  }

  .dt-row-ns {
    display: table-row;
  }

  .dt-row-group-ns {
    display: table-row-group;
  }

  .dt-column-ns {
    display: table-column;
  }

  .dt-column-group-ns {
    display: table-column-group;
  }

  .dt--fixed-ns {
    table-layout: fixed;
    width: 100%;
  }

  .flex-ns {
    display: flex;
  }

  .inline-flex-ns {
    display: inline-flex;
  }

  .flex-auto-ns {
    min-width: 0;
    min-height: 0;
    flex: auto;
  }

  .flex-none-ns {
    flex: none;
  }

  .flex-column-ns {
    flex-direction: column;
  }

  .flex-row-ns {
    flex-direction: row;
  }

  .flex-wrap-ns {
    flex-wrap: wrap;
  }

  .flex-nowrap-ns {
    flex-wrap: nowrap;
  }

  .flex-wrap-reverse-ns {
    flex-wrap: wrap-reverse;
  }

  .flex-column-reverse-ns {
    flex-direction: column-reverse;
  }

  .flex-row-reverse-ns {
    flex-direction: row-reverse;
  }

  .items-start-ns {
    align-items: flex-start;
  }

  .items-end-ns {
    align-items: flex-end;
  }

  .items-center-ns {
    align-items: center;
  }

  .items-baseline-ns {
    align-items: baseline;
  }

  .items-stretch-ns {
    align-items: stretch;
  }

  .self-start-ns {
    align-self: flex-start;
  }

  .self-end-ns {
    align-self: flex-end;
  }

  .self-center-ns {
    align-self: center;
  }

  .self-baseline-ns {
    align-self: baseline;
  }

  .self-stretch-ns {
    align-self: stretch;
  }

  .justify-start-ns {
    justify-content: flex-start;
  }

  .justify-end-ns {
    justify-content: flex-end;
  }

  .justify-center-ns {
    justify-content: center;
  }

  .justify-between-ns {
    justify-content: space-between;
  }

  .justify-around-ns {
    justify-content: space-around;
  }

  .content-start-ns {
    align-content: flex-start;
  }

  .content-end-ns {
    align-content: flex-end;
  }

  .content-center-ns {
    align-content: center;
  }

  .content-between-ns {
    align-content: space-between;
  }

  .content-around-ns {
    align-content: space-around;
  }

  .content-stretch-ns {
    align-content: stretch;
  }

  .order-0-ns {
    order: 0;
  }

  .order-1-ns {
    order: 1;
  }

  .order-2-ns {
    order: 2;
  }

  .order-3-ns {
    order: 3;
  }

  .order-4-ns {
    order: 4;
  }

  .order-5-ns {
    order: 5;
  }

  .order-6-ns {
    order: 6;
  }

  .order-7-ns {
    order: 7;
  }

  .order-8-ns {
    order: 8;
  }

  .order-last-ns {
    order: 99999;
  }

  .flex-grow-0-ns {
    flex-grow: 0;
  }

  .flex-grow-1-ns {
    flex-grow: 1;
  }

  .flex-shrink-0-ns {
    flex-shrink: 0;
  }

  .flex-shrink-1-ns {
    flex-shrink: 1;
  }

  .fl-ns {
    float: left;
    _display: inline;
  }

  .fr-ns {
    float: right;
    _display: inline;
  }

  .fn-ns {
    float: none;
  }

  .i-ns {
    font-style: italic;
  }

  .fs-normal-ns {
    font-style: normal;
  }

  .normal-ns {
    font-weight: normal;
  }

  .b-ns {
    font-weight: bold;
  }

  .fw1-ns {
    font-weight: 100;
  }

  .fw2-ns {
    font-weight: 200;
  }

  .fw3-ns {
    font-weight: 300;
  }

  .fw4-ns {
    font-weight: 400;
  }

  .fw5-ns {
    font-weight: 500;
  }

  .fw6-ns {
    font-weight: 600;
  }

  .fw7-ns {
    font-weight: 700;
  }

  .fw8-ns {
    font-weight: 800;
  }

  .fw9-ns {
    font-weight: 900;
  }

  .h1-ns {
    height: 1rem;
  }

  .h2-ns {
    height: 2rem;
  }

  .h3-ns {
    height: 4rem;
  }

  .h4-ns {
    height: 8rem;
  }

  .h5-ns {
    height: 16rem;
  }

  .h-25-ns {
    height: 25%;
  }

  .h-50-ns {
    height: 50%;
  }

  .h-75-ns {
    height: 75%;
  }

  .h-100-ns {
    height: 100%;
  }

  .min-h-100-ns {
    min-height: 100%;
  }

  .vh-25-ns {
    height: 25vh;
  }

  .vh-50-ns {
    height: 50vh;
  }

  .vh-75-ns {
    height: 75vh;
  }

  .vh-100-ns {
    height: 100vh;
  }

  .min-vh-100-ns {
    min-height: 100vh;
  }

  .h-auto-ns {
    height: auto;
  }

  .h-inherit-ns {
    height: inherit;
  }

  .tracked-ns {
    letter-spacing: .1em;
  }

  .tracked-tight-ns {
    letter-spacing: -.05em;
  }

  .tracked-mega-ns {
    letter-spacing: .25em;
  }

  .lh-solid-ns {
    line-height: 1;
  }

  .lh-title-ns {
    line-height: 1.25;
  }

  .lh-copy-ns {
    line-height: 1.5;
  }

  .mw-100-ns {
    max-width: 100%;
  }

  .mw1-ns {
    max-width: 1rem;
  }

  .mw2-ns {
    max-width: 2rem;
  }

  .mw3-ns {
    max-width: 4rem;
  }

  .mw4-ns {
    max-width: 8rem;
  }

  .mw5-ns {
    max-width: 16rem;
  }

  .mw6-ns {
    max-width: 32rem;
  }

  .mw7-ns {
    max-width: 48rem;
  }

  .mw8-ns {
    max-width: 64rem;
  }

  .mw9-ns {
    max-width: 96rem;
  }

  .mw-none-ns {
    max-width: none;
  }

  .w1-ns {
    width: 1rem;
  }

  .w2-ns {
    width: 2rem;
  }

  .w3-ns {
    width: 4rem;
  }

  .w4-ns {
    width: 8rem;
  }

  .w5-ns {
    width: 16rem;
  }

  .w-10-ns {
    width: 10%;
  }

  .w-20-ns {
    width: 20%;
  }

  .w-25-ns {
    width: 25%;
  }

  .w-30-ns {
    width: 30%;
  }

  .w-33-ns {
    width: 33%;
  }

  .w-34-ns {
    width: 34%;
  }

  .w-40-ns {
    width: 40%;
  }

  .w-50-ns {
    width: 50%;
  }

  .w-60-ns {
    width: 60%;
  }

  .w-70-ns {
    width: 70%;
  }

  .w-75-ns {
    width: 75%;
  }

  .w-80-ns {
    width: 80%;
  }

  .w-90-ns {
    width: 90%;
  }

  .w-100-ns {
    width: 100%;
  }

  .w-third-ns {
    width: 33.3333%;
  }

  .w-two-thirds-ns {
    width: 66.6667%;
  }

  .w-auto-ns {
    width: auto;
  }

  .overflow-visible-ns {
    overflow: visible;
  }

  .overflow-hidden-ns {
    overflow: hidden;
  }

  .overflow-scroll-ns {
    overflow: scroll;
  }

  .overflow-auto-ns {
    overflow: auto;
  }

  .overflow-x-visible-ns {
    overflow-x: visible;
  }

  .overflow-x-hidden-ns {
    overflow-x: hidden;
  }

  .overflow-x-scroll-ns {
    overflow-x: scroll;
  }

  .overflow-x-auto-ns {
    overflow-x: auto;
  }

  .overflow-y-visible-ns {
    overflow-y: visible;
  }

  .overflow-y-hidden-ns {
    overflow-y: hidden;
  }

  .overflow-y-scroll-ns {
    overflow-y: scroll;
  }

  .overflow-y-auto-ns {
    overflow-y: auto;
  }

  .static-ns {
    position: static;
  }

  .relative-ns {
    position: relative;
  }

  .absolute-ns {
    position: absolute;
  }

  .fixed-ns {
    position: fixed;
  }

  .rotate-45-ns {
    transform: rotate(45deg);
  }

  .rotate-90-ns {
    transform: rotate(90deg);
  }

  .rotate-135-ns {
    transform: rotate(135deg);
  }

  .rotate-180-ns {
    transform: rotate(180deg);
  }

  .rotate-225-ns {
    transform: rotate(225deg);
  }

  .rotate-270-ns {
    transform: rotate(270deg);
  }

  .rotate-315-ns {
    transform: rotate(315deg);
  }

  .pa0-ns {
    padding: 0;
  }

  .pa1-ns {
    padding: .25rem;
  }

  .pa2-ns {
    padding: .5rem;
  }

  .pa3-ns {
    padding: 1rem;
  }

  .pa4-ns {
    padding: 2rem;
  }

  .pa5-ns {
    padding: 4rem;
  }

  .pa6-ns {
    padding: 8rem;
  }

  .pa7-ns {
    padding: 16rem;
  }

  .pl0-ns {
    padding-left: 0;
  }

  .pl1-ns {
    padding-left: .25rem;
  }

  .pl2-ns {
    padding-left: .5rem;
  }

  .pl3-ns {
    padding-left: 1rem;
  }

  .pl4-ns {
    padding-left: 2rem;
  }

  .pl5-ns {
    padding-left: 4rem;
  }

  .pl6-ns {
    padding-left: 8rem;
  }

  .pl7-ns {
    padding-left: 16rem;
  }

  .pr0-ns {
    padding-right: 0;
  }

  .pr1-ns {
    padding-right: .25rem;
  }

  .pr2-ns {
    padding-right: .5rem;
  }

  .pr3-ns {
    padding-right: 1rem;
  }

  .pr4-ns {
    padding-right: 2rem;
  }

  .pr5-ns {
    padding-right: 4rem;
  }

  .pr6-ns {
    padding-right: 8rem;
  }

  .pr7-ns {
    padding-right: 16rem;
  }

  .pb0-ns {
    padding-bottom: 0;
  }

  .pb1-ns {
    padding-bottom: .25rem;
  }

  .pb2-ns {
    padding-bottom: .5rem;
  }

  .pb3-ns {
    padding-bottom: 1rem;
  }

  .pb4-ns {
    padding-bottom: 2rem;
  }

  .pb5-ns {
    padding-bottom: 4rem;
  }

  .pb6-ns {
    padding-bottom: 8rem;
  }

  .pb7-ns {
    padding-bottom: 16rem;
  }

  .pt0-ns {
    padding-top: 0;
  }

  .pt1-ns {
    padding-top: .25rem;
  }

  .pt2-ns {
    padding-top: .5rem;
  }

  .pt3-ns {
    padding-top: 1rem;
  }

  .pt4-ns {
    padding-top: 2rem;
  }

  .pt5-ns {
    padding-top: 4rem;
  }

  .pt6-ns {
    padding-top: 8rem;
  }

  .pt7-ns {
    padding-top: 16rem;
  }

  .pv0-ns {
    padding-top: 0;
    padding-bottom: 0;
  }

  .pv1-ns {
    padding-top: .25rem;
    padding-bottom: .25rem;
  }

  .pv2-ns {
    padding-top: .5rem;
    padding-bottom: .5rem;
  }

  .pv3-ns {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .pv4-ns {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .pv5-ns {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  .pv6-ns {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }

  .pv7-ns {
    padding-top: 16rem;
    padding-bottom: 16rem;
  }

  .ph0-ns {
    padding-left: 0;
    padding-right: 0;
  }

  .ph1-ns {
    padding-left: .25rem;
    padding-right: .25rem;
  }

  .ph2-ns {
    padding-left: .5rem;
    padding-right: .5rem;
  }

  .ph3-ns {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .ph4-ns {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .ph5-ns {
    padding-left: 4rem;
    padding-right: 4rem;
  }

  .ph6-ns {
    padding-left: 8rem;
    padding-right: 8rem;
  }

  .ph7-ns {
    padding-left: 16rem;
    padding-right: 16rem;
  }

  .ma0-ns {
    margin: 0;
  }

  .ma1-ns {
    margin: .25rem;
  }

  .ma2-ns {
    margin: .5rem;
  }

  .ma3-ns {
    margin: 1rem;
  }

  .ma4-ns {
    margin: 2rem;
  }

  .ma5-ns {
    margin: 4rem;
  }

  .ma6-ns {
    margin: 8rem;
  }

  .ma7-ns {
    margin: 16rem;
  }

  .ml0-ns {
    margin-left: 0;
  }

  .ml1-ns {
    margin-left: .25rem;
  }

  .ml2-ns {
    margin-left: .5rem;
  }

  .ml3-ns {
    margin-left: 1rem;
  }

  .ml4-ns {
    margin-left: 2rem;
  }

  .ml5-ns {
    margin-left: 4rem;
  }

  .ml6-ns {
    margin-left: 8rem;
  }

  .ml7-ns {
    margin-left: 16rem;
  }

  .mr0-ns {
    margin-right: 0;
  }

  .mr1-ns {
    margin-right: .25rem;
  }

  .mr2-ns {
    margin-right: .5rem;
  }

  .mr3-ns {
    margin-right: 1rem;
  }

  .mr4-ns {
    margin-right: 2rem;
  }

  .mr5-ns {
    margin-right: 4rem;
  }

  .mr6-ns {
    margin-right: 8rem;
  }

  .mr7-ns {
    margin-right: 16rem;
  }

  .mb0-ns {
    margin-bottom: 0;
  }

  .mb1-ns {
    margin-bottom: .25rem;
  }

  .mb2-ns {
    margin-bottom: .5rem;
  }

  .mb3-ns {
    margin-bottom: 1rem;
  }

  .mb4-ns {
    margin-bottom: 2rem;
  }

  .mb5-ns {
    margin-bottom: 4rem;
  }

  .mb6-ns {
    margin-bottom: 8rem;
  }

  .mb7-ns {
    margin-bottom: 16rem;
  }

  .mt0-ns {
    margin-top: 0;
  }

  .mt1-ns {
    margin-top: .25rem;
  }

  .mt2-ns {
    margin-top: .5rem;
  }

  .mt3-ns {
    margin-top: 1rem;
  }

  .mt4-ns {
    margin-top: 2rem;
  }

  .mt5-ns {
    margin-top: 4rem;
  }

  .mt6-ns {
    margin-top: 8rem;
  }

  .mt7-ns {
    margin-top: 16rem;
  }

  .mv0-ns {
    margin-top: 0;
    margin-bottom: 0;
  }

  .mv1-ns {
    margin-top: .25rem;
    margin-bottom: .25rem;
  }

  .mv2-ns {
    margin-top: .5rem;
    margin-bottom: .5rem;
  }

  .mv3-ns {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .mv4-ns {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .mv5-ns {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }

  .mv6-ns {
    margin-top: 8rem;
    margin-bottom: 8rem;
  }

  .mv7-ns {
    margin-top: 16rem;
    margin-bottom: 16rem;
  }

  .mh0-ns {
    margin-left: 0;
    margin-right: 0;
  }

  .mh1-ns {
    margin-left: .25rem;
    margin-right: .25rem;
  }

  .mh2-ns {
    margin-left: .5rem;
    margin-right: .5rem;
  }

  .mh3-ns {
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .mh4-ns {
    margin-left: 2rem;
    margin-right: 2rem;
  }

  .mh5-ns {
    margin-left: 4rem;
    margin-right: 4rem;
  }

  .mh6-ns {
    margin-left: 8rem;
    margin-right: 8rem;
  }

  .mh7-ns {
    margin-left: 16rem;
    margin-right: 16rem;
  }

  .na1-ns {
    margin: -.25rem;
  }

  .na2-ns {
    margin: -.5rem;
  }

  .na3-ns {
    margin: -1rem;
  }

  .na4-ns {
    margin: -2rem;
  }

  .na5-ns {
    margin: -4rem;
  }

  .na6-ns {
    margin: -8rem;
  }

  .na7-ns {
    margin: -16rem;
  }

  .nl1-ns {
    margin-left: -.25rem;
  }

  .nl2-ns {
    margin-left: -.5rem;
  }

  .nl3-ns {
    margin-left: -1rem;
  }

  .nl4-ns {
    margin-left: -2rem;
  }

  .nl5-ns {
    margin-left: -4rem;
  }

  .nl6-ns {
    margin-left: -8rem;
  }

  .nl7-ns {
    margin-left: -16rem;
  }

  .nr1-ns {
    margin-right: -.25rem;
  }

  .nr2-ns {
    margin-right: -.5rem;
  }

  .nr3-ns {
    margin-right: -1rem;
  }

  .nr4-ns {
    margin-right: -2rem;
  }

  .nr5-ns {
    margin-right: -4rem;
  }

  .nr6-ns {
    margin-right: -8rem;
  }

  .nr7-ns {
    margin-right: -16rem;
  }

  .nb1-ns {
    margin-bottom: -.25rem;
  }

  .nb2-ns {
    margin-bottom: -.5rem;
  }

  .nb3-ns {
    margin-bottom: -1rem;
  }

  .nb4-ns {
    margin-bottom: -2rem;
  }

  .nb5-ns {
    margin-bottom: -4rem;
  }

  .nb6-ns {
    margin-bottom: -8rem;
  }

  .nb7-ns {
    margin-bottom: -16rem;
  }

  .nt1-ns {
    margin-top: -.25rem;
  }

  .nt2-ns {
    margin-top: -.5rem;
  }

  .nt3-ns {
    margin-top: -1rem;
  }

  .nt4-ns {
    margin-top: -2rem;
  }

  .nt5-ns {
    margin-top: -4rem;
  }

  .nt6-ns {
    margin-top: -8rem;
  }

  .nt7-ns {
    margin-top: -16rem;
  }

  .strike-ns {
    text-decoration: line-through;
  }

  .underline-ns {
    text-decoration: underline;
  }

  .no-underline-ns {
    text-decoration: none;
  }

  .tl-ns {
    text-align: left;
  }

  .tr-ns {
    text-align: right;
  }

  .tc-ns {
    text-align: center;
  }

  .tj-ns {
    text-align: justify;
  }

  .ttc-ns {
    text-transform: capitalize;
  }

  .ttl-ns {
    text-transform: lowercase;
  }

  .ttu-ns {
    text-transform: uppercase;
  }

  .ttn-ns {
    text-transform: none;
  }

  .f-6-ns, .f-headline-ns {
    font-size: 6rem;
  }

  .f-5-ns, .f-subheadline-ns {
    font-size: 5rem;
  }

  .f1-ns {
    font-size: 3rem;
  }

  .f2-ns {
    font-size: 2.25rem;
  }

  .f3-ns {
    font-size: 1.5rem;
  }

  .f4-ns {
    font-size: 1.25rem;
  }

  .f5-ns {
    font-size: 1rem;
  }

  .f6-ns {
    font-size: .875rem;
  }

  .f7-ns {
    font-size: .75rem;
  }

  .measure-ns {
    max-width: 30em;
  }

  .measure-wide-ns {
    max-width: 34em;
  }

  .measure-narrow-ns {
    max-width: 20em;
  }

  .indent-ns {
    text-indent: 1em;
    margin-top: 0;
    margin-bottom: 0;
  }

  .small-caps-ns {
    font-variant: small-caps;
  }

  .truncate-ns {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .center-ns {
    margin-left: auto;
    margin-right: auto;
  }

  .mr-auto-ns {
    margin-right: auto;
  }

  .ml-auto-ns {
    margin-left: auto;
  }

  .clip-ns {
    clip: rect(1px 1px 1px 1px);
    clip: rect(1px, 1px, 1px, 1px);
    _position: absolute !important;
    position: fixed !important;
  }

  .ws-normal-ns {
    white-space: normal;
  }

  .nowrap-ns {
    white-space: nowrap;
  }

  .pre-ns {
    white-space: pre;
  }

  .v-base-ns {
    vertical-align: baseline;
  }

  .v-mid-ns {
    vertical-align: middle;
  }

  .v-top-ns {
    vertical-align: top;
  }

  .v-btm-ns {
    vertical-align: bottom;
  }
}

@media screen and (min-width: 30em) and (max-width: 60em) {
  .aspect-ratio-m {
    height: 0;
    position: relative;
  }

  .aspect-ratio--16x9-m {
    padding-bottom: 56.25%;
  }

  .aspect-ratio--9x16-m {
    padding-bottom: 177.77%;
  }

  .aspect-ratio--4x3-m {
    padding-bottom: 75%;
  }

  .aspect-ratio--3x4-m {
    padding-bottom: 133.33%;
  }

  .aspect-ratio--6x4-m {
    padding-bottom: 66.6%;
  }

  .aspect-ratio--4x6-m {
    padding-bottom: 150%;
  }

  .aspect-ratio--8x5-m {
    padding-bottom: 62.5%;
  }

  .aspect-ratio--5x8-m {
    padding-bottom: 160%;
  }

  .aspect-ratio--7x5-m {
    padding-bottom: 71.42%;
  }

  .aspect-ratio--5x7-m {
    padding-bottom: 140%;
  }

  .aspect-ratio--1x1-m {
    padding-bottom: 100%;
  }

  .aspect-ratio--object-m {
    width: 100%;
    height: 100%;
    z-index: 100;
    position: absolute;
    inset: 0;
  }

  .cover-m {
    background-size: cover !important;
  }

  .contain-m {
    background-size: contain !important;
  }

  .bg-center-m {
    background-position: center;
    background-repeat: no-repeat;
  }

  .bg-top-m {
    background-position: top;
    background-repeat: no-repeat;
  }

  .bg-right-m {
    background-position: 100%;
    background-repeat: no-repeat;
  }

  .bg-bottom-m {
    background-position: bottom;
    background-repeat: no-repeat;
  }

  .bg-left-m {
    background-position: 0;
    background-repeat: no-repeat;
  }

  .outline-m {
    outline: 1px solid;
  }

  .outline-transparent-m {
    outline: 1px solid #0000;
  }

  .outline-0-m {
    outline: 0;
  }

  .ba-m {
    border-style: solid;
    border-width: 1px;
  }

  .bt-m {
    border-top-style: solid;
    border-top-width: 1px;
  }

  .br-m {
    border-right-style: solid;
    border-right-width: 1px;
  }

  .bb-m {
    border-bottom-style: solid;
    border-bottom-width: 1px;
  }

  .bl-m {
    border-left-style: solid;
    border-left-width: 1px;
  }

  .bn-m {
    border-style: none;
    border-width: 0;
  }

  .br0-m {
    border-radius: 0;
  }

  .br1-m {
    border-radius: .125rem;
  }

  .br2-m {
    border-radius: .25rem;
  }

  .br3-m {
    border-radius: .5rem;
  }

  .br4-m {
    border-radius: 1rem;
  }

  .br-100-m {
    border-radius: 100%;
  }

  .br-pill-m {
    border-radius: 9999px;
  }

  .br--bottom-m {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .br--top-m {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .br--right-m {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .br--left-m {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .br-inherit-m {
    border-radius: inherit;
  }

  .br-initial-m {
    border-radius: initial;
  }

  .br-unset-m {
    border-radius: unset;
  }

  .b--dotted-m {
    border-style: dotted;
  }

  .b--dashed-m {
    border-style: dashed;
  }

  .b--solid-m {
    border-style: solid;
  }

  .b--none-m {
    border-style: none;
  }

  .bw0-m {
    border-width: 0;
  }

  .bw1-m {
    border-width: .125rem;
  }

  .bw2-m {
    border-width: .25rem;
  }

  .bw3-m {
    border-width: .5rem;
  }

  .bw4-m {
    border-width: 1rem;
  }

  .bw5-m {
    border-width: 2rem;
  }

  .bt-0-m {
    border-top-width: 0;
  }

  .br-0-m {
    border-right-width: 0;
  }

  .bb-0-m {
    border-bottom-width: 0;
  }

  .bl-0-m {
    border-left-width: 0;
  }

  .shadow-1-m {
    box-shadow: 0 0 4px 2px #0003;
  }

  .shadow-2-m {
    box-shadow: 0 0 8px 2px #0003;
  }

  .shadow-3-m {
    box-shadow: 2px 2px 4px 2px #0003;
  }

  .shadow-4-m {
    box-shadow: 2px 2px 8px #0003;
  }

  .shadow-5-m {
    box-shadow: 4px 4px 8px #0003;
  }

  .top-0-m {
    top: 0;
  }

  .left-0-m {
    left: 0;
  }

  .right-0-m {
    right: 0;
  }

  .bottom-0-m {
    bottom: 0;
  }

  .top-1-m {
    top: 1rem;
  }

  .left-1-m {
    left: 1rem;
  }

  .right-1-m {
    right: 1rem;
  }

  .bottom-1-m {
    bottom: 1rem;
  }

  .top-2-m {
    top: 2rem;
  }

  .left-2-m {
    left: 2rem;
  }

  .right-2-m {
    right: 2rem;
  }

  .bottom-2-m {
    bottom: 2rem;
  }

  .top--1-m {
    top: -1rem;
  }

  .right--1-m {
    right: -1rem;
  }

  .bottom--1-m {
    bottom: -1rem;
  }

  .left--1-m {
    left: -1rem;
  }

  .top--2-m {
    top: -2rem;
  }

  .right--2-m {
    right: -2rem;
  }

  .bottom--2-m {
    bottom: -2rem;
  }

  .left--2-m {
    left: -2rem;
  }

  .absolute--fill-m {
    inset: 0;
  }

  .cl-m {
    clear: left;
  }

  .cr-m {
    clear: right;
  }

  .cb-m {
    clear: both;
  }

  .cn-m {
    clear: none;
  }

  .dn-m {
    display: none;
  }

  .di-m {
    display: inline;
  }

  .db-m {
    display: block;
  }

  .dib-m {
    display: inline-block;
  }

  .dit-m {
    display: inline-table;
  }

  .dt-m {
    display: table;
  }

  .dtc-m {
    display: table-cell;
  }

  .dt-row-m {
    display: table-row;
  }

  .dt-row-group-m {
    display: table-row-group;
  }

  .dt-column-m {
    display: table-column;
  }

  .dt-column-group-m {
    display: table-column-group;
  }

  .dt--fixed-m {
    table-layout: fixed;
    width: 100%;
  }

  .flex-m {
    display: flex;
  }

  .inline-flex-m {
    display: inline-flex;
  }

  .flex-auto-m {
    min-width: 0;
    min-height: 0;
    flex: auto;
  }

  .flex-none-m {
    flex: none;
  }

  .flex-column-m {
    flex-direction: column;
  }

  .flex-row-m {
    flex-direction: row;
  }

  .flex-wrap-m {
    flex-wrap: wrap;
  }

  .flex-nowrap-m {
    flex-wrap: nowrap;
  }

  .flex-wrap-reverse-m {
    flex-wrap: wrap-reverse;
  }

  .flex-column-reverse-m {
    flex-direction: column-reverse;
  }

  .flex-row-reverse-m {
    flex-direction: row-reverse;
  }

  .items-start-m {
    align-items: flex-start;
  }

  .items-end-m {
    align-items: flex-end;
  }

  .items-center-m {
    align-items: center;
  }

  .items-baseline-m {
    align-items: baseline;
  }

  .items-stretch-m {
    align-items: stretch;
  }

  .self-start-m {
    align-self: flex-start;
  }

  .self-end-m {
    align-self: flex-end;
  }

  .self-center-m {
    align-self: center;
  }

  .self-baseline-m {
    align-self: baseline;
  }

  .self-stretch-m {
    align-self: stretch;
  }

  .justify-start-m {
    justify-content: flex-start;
  }

  .justify-end-m {
    justify-content: flex-end;
  }

  .justify-center-m {
    justify-content: center;
  }

  .justify-between-m {
    justify-content: space-between;
  }

  .justify-around-m {
    justify-content: space-around;
  }

  .content-start-m {
    align-content: flex-start;
  }

  .content-end-m {
    align-content: flex-end;
  }

  .content-center-m {
    align-content: center;
  }

  .content-between-m {
    align-content: space-between;
  }

  .content-around-m {
    align-content: space-around;
  }

  .content-stretch-m {
    align-content: stretch;
  }

  .order-0-m {
    order: 0;
  }

  .order-1-m {
    order: 1;
  }

  .order-2-m {
    order: 2;
  }

  .order-3-m {
    order: 3;
  }

  .order-4-m {
    order: 4;
  }

  .order-5-m {
    order: 5;
  }

  .order-6-m {
    order: 6;
  }

  .order-7-m {
    order: 7;
  }

  .order-8-m {
    order: 8;
  }

  .order-last-m {
    order: 99999;
  }

  .flex-grow-0-m {
    flex-grow: 0;
  }

  .flex-grow-1-m {
    flex-grow: 1;
  }

  .flex-shrink-0-m {
    flex-shrink: 0;
  }

  .flex-shrink-1-m {
    flex-shrink: 1;
  }

  .fl-m {
    float: left;
    _display: inline;
  }

  .fr-m {
    float: right;
    _display: inline;
  }

  .fn-m {
    float: none;
  }

  .i-m {
    font-style: italic;
  }

  .fs-normal-m {
    font-style: normal;
  }

  .normal-m {
    font-weight: normal;
  }

  .b-m {
    font-weight: bold;
  }

  .fw1-m {
    font-weight: 100;
  }

  .fw2-m {
    font-weight: 200;
  }

  .fw3-m {
    font-weight: 300;
  }

  .fw4-m {
    font-weight: 400;
  }

  .fw5-m {
    font-weight: 500;
  }

  .fw6-m {
    font-weight: 600;
  }

  .fw7-m {
    font-weight: 700;
  }

  .fw8-m {
    font-weight: 800;
  }

  .fw9-m {
    font-weight: 900;
  }

  .h1-m {
    height: 1rem;
  }

  .h2-m {
    height: 2rem;
  }

  .h3-m {
    height: 4rem;
  }

  .h4-m {
    height: 8rem;
  }

  .h5-m {
    height: 16rem;
  }

  .h-25-m {
    height: 25%;
  }

  .h-50-m {
    height: 50%;
  }

  .h-75-m {
    height: 75%;
  }

  .h-100-m {
    height: 100%;
  }

  .min-h-100-m {
    min-height: 100%;
  }

  .vh-25-m {
    height: 25vh;
  }

  .vh-50-m {
    height: 50vh;
  }

  .vh-75-m {
    height: 75vh;
  }

  .vh-100-m {
    height: 100vh;
  }

  .min-vh-100-m {
    min-height: 100vh;
  }

  .h-auto-m {
    height: auto;
  }

  .h-inherit-m {
    height: inherit;
  }

  .tracked-m {
    letter-spacing: .1em;
  }

  .tracked-tight-m {
    letter-spacing: -.05em;
  }

  .tracked-mega-m {
    letter-spacing: .25em;
  }

  .lh-solid-m {
    line-height: 1;
  }

  .lh-title-m {
    line-height: 1.25;
  }

  .lh-copy-m {
    line-height: 1.5;
  }

  .mw-100-m {
    max-width: 100%;
  }

  .mw1-m {
    max-width: 1rem;
  }

  .mw2-m {
    max-width: 2rem;
  }

  .mw3-m {
    max-width: 4rem;
  }

  .mw4-m {
    max-width: 8rem;
  }

  .mw5-m {
    max-width: 16rem;
  }

  .mw6-m {
    max-width: 32rem;
  }

  .mw7-m {
    max-width: 48rem;
  }

  .mw8-m {
    max-width: 64rem;
  }

  .mw9-m {
    max-width: 96rem;
  }

  .mw-none-m {
    max-width: none;
  }

  .w1-m {
    width: 1rem;
  }

  .w2-m {
    width: 2rem;
  }

  .w3-m {
    width: 4rem;
  }

  .w4-m {
    width: 8rem;
  }

  .w5-m {
    width: 16rem;
  }

  .w-10-m {
    width: 10%;
  }

  .w-20-m {
    width: 20%;
  }

  .w-25-m {
    width: 25%;
  }

  .w-30-m {
    width: 30%;
  }

  .w-33-m {
    width: 33%;
  }

  .w-34-m {
    width: 34%;
  }

  .w-40-m {
    width: 40%;
  }

  .w-50-m {
    width: 50%;
  }

  .w-60-m {
    width: 60%;
  }

  .w-70-m {
    width: 70%;
  }

  .w-75-m {
    width: 75%;
  }

  .w-80-m {
    width: 80%;
  }

  .w-90-m {
    width: 90%;
  }

  .w-100-m {
    width: 100%;
  }

  .w-third-m {
    width: 33.3333%;
  }

  .w-two-thirds-m {
    width: 66.6667%;
  }

  .w-auto-m {
    width: auto;
  }

  .overflow-visible-m {
    overflow: visible;
  }

  .overflow-hidden-m {
    overflow: hidden;
  }

  .overflow-scroll-m {
    overflow: scroll;
  }

  .overflow-auto-m {
    overflow: auto;
  }

  .overflow-x-visible-m {
    overflow-x: visible;
  }

  .overflow-x-hidden-m {
    overflow-x: hidden;
  }

  .overflow-x-scroll-m {
    overflow-x: scroll;
  }

  .overflow-x-auto-m {
    overflow-x: auto;
  }

  .overflow-y-visible-m {
    overflow-y: visible;
  }

  .overflow-y-hidden-m {
    overflow-y: hidden;
  }

  .overflow-y-scroll-m {
    overflow-y: scroll;
  }

  .overflow-y-auto-m {
    overflow-y: auto;
  }

  .static-m {
    position: static;
  }

  .relative-m {
    position: relative;
  }

  .absolute-m {
    position: absolute;
  }

  .fixed-m {
    position: fixed;
  }

  .rotate-45-m {
    transform: rotate(45deg);
  }

  .rotate-90-m {
    transform: rotate(90deg);
  }

  .rotate-135-m {
    transform: rotate(135deg);
  }

  .rotate-180-m {
    transform: rotate(180deg);
  }

  .rotate-225-m {
    transform: rotate(225deg);
  }

  .rotate-270-m {
    transform: rotate(270deg);
  }

  .rotate-315-m {
    transform: rotate(315deg);
  }

  .pa0-m {
    padding: 0;
  }

  .pa1-m {
    padding: .25rem;
  }

  .pa2-m {
    padding: .5rem;
  }

  .pa3-m {
    padding: 1rem;
  }

  .pa4-m {
    padding: 2rem;
  }

  .pa5-m {
    padding: 4rem;
  }

  .pa6-m {
    padding: 8rem;
  }

  .pa7-m {
    padding: 16rem;
  }

  .pl0-m {
    padding-left: 0;
  }

  .pl1-m {
    padding-left: .25rem;
  }

  .pl2-m {
    padding-left: .5rem;
  }

  .pl3-m {
    padding-left: 1rem;
  }

  .pl4-m {
    padding-left: 2rem;
  }

  .pl5-m {
    padding-left: 4rem;
  }

  .pl6-m {
    padding-left: 8rem;
  }

  .pl7-m {
    padding-left: 16rem;
  }

  .pr0-m {
    padding-right: 0;
  }

  .pr1-m {
    padding-right: .25rem;
  }

  .pr2-m {
    padding-right: .5rem;
  }

  .pr3-m {
    padding-right: 1rem;
  }

  .pr4-m {
    padding-right: 2rem;
  }

  .pr5-m {
    padding-right: 4rem;
  }

  .pr6-m {
    padding-right: 8rem;
  }

  .pr7-m {
    padding-right: 16rem;
  }

  .pb0-m {
    padding-bottom: 0;
  }

  .pb1-m {
    padding-bottom: .25rem;
  }

  .pb2-m {
    padding-bottom: .5rem;
  }

  .pb3-m {
    padding-bottom: 1rem;
  }

  .pb4-m {
    padding-bottom: 2rem;
  }

  .pb5-m {
    padding-bottom: 4rem;
  }

  .pb6-m {
    padding-bottom: 8rem;
  }

  .pb7-m {
    padding-bottom: 16rem;
  }

  .pt0-m {
    padding-top: 0;
  }

  .pt1-m {
    padding-top: .25rem;
  }

  .pt2-m {
    padding-top: .5rem;
  }

  .pt3-m {
    padding-top: 1rem;
  }

  .pt4-m {
    padding-top: 2rem;
  }

  .pt5-m {
    padding-top: 4rem;
  }

  .pt6-m {
    padding-top: 8rem;
  }

  .pt7-m {
    padding-top: 16rem;
  }

  .pv0-m {
    padding-top: 0;
    padding-bottom: 0;
  }

  .pv1-m {
    padding-top: .25rem;
    padding-bottom: .25rem;
  }

  .pv2-m {
    padding-top: .5rem;
    padding-bottom: .5rem;
  }

  .pv3-m {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .pv4-m {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .pv5-m {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  .pv6-m {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }

  .pv7-m {
    padding-top: 16rem;
    padding-bottom: 16rem;
  }

  .ph0-m {
    padding-left: 0;
    padding-right: 0;
  }

  .ph1-m {
    padding-left: .25rem;
    padding-right: .25rem;
  }

  .ph2-m {
    padding-left: .5rem;
    padding-right: .5rem;
  }

  .ph3-m {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .ph4-m {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .ph5-m {
    padding-left: 4rem;
    padding-right: 4rem;
  }

  .ph6-m {
    padding-left: 8rem;
    padding-right: 8rem;
  }

  .ph7-m {
    padding-left: 16rem;
    padding-right: 16rem;
  }

  .ma0-m {
    margin: 0;
  }

  .ma1-m {
    margin: .25rem;
  }

  .ma2-m {
    margin: .5rem;
  }

  .ma3-m {
    margin: 1rem;
  }

  .ma4-m {
    margin: 2rem;
  }

  .ma5-m {
    margin: 4rem;
  }

  .ma6-m {
    margin: 8rem;
  }

  .ma7-m {
    margin: 16rem;
  }

  .ml0-m {
    margin-left: 0;
  }

  .ml1-m {
    margin-left: .25rem;
  }

  .ml2-m {
    margin-left: .5rem;
  }

  .ml3-m {
    margin-left: 1rem;
  }

  .ml4-m {
    margin-left: 2rem;
  }

  .ml5-m {
    margin-left: 4rem;
  }

  .ml6-m {
    margin-left: 8rem;
  }

  .ml7-m {
    margin-left: 16rem;
  }

  .mr0-m {
    margin-right: 0;
  }

  .mr1-m {
    margin-right: .25rem;
  }

  .mr2-m {
    margin-right: .5rem;
  }

  .mr3-m {
    margin-right: 1rem;
  }

  .mr4-m {
    margin-right: 2rem;
  }

  .mr5-m {
    margin-right: 4rem;
  }

  .mr6-m {
    margin-right: 8rem;
  }

  .mr7-m {
    margin-right: 16rem;
  }

  .mb0-m {
    margin-bottom: 0;
  }

  .mb1-m {
    margin-bottom: .25rem;
  }

  .mb2-m {
    margin-bottom: .5rem;
  }

  .mb3-m {
    margin-bottom: 1rem;
  }

  .mb4-m {
    margin-bottom: 2rem;
  }

  .mb5-m {
    margin-bottom: 4rem;
  }

  .mb6-m {
    margin-bottom: 8rem;
  }

  .mb7-m {
    margin-bottom: 16rem;
  }

  .mt0-m {
    margin-top: 0;
  }

  .mt1-m {
    margin-top: .25rem;
  }

  .mt2-m {
    margin-top: .5rem;
  }

  .mt3-m {
    margin-top: 1rem;
  }

  .mt4-m {
    margin-top: 2rem;
  }

  .mt5-m {
    margin-top: 4rem;
  }

  .mt6-m {
    margin-top: 8rem;
  }

  .mt7-m {
    margin-top: 16rem;
  }

  .mv0-m {
    margin-top: 0;
    margin-bottom: 0;
  }

  .mv1-m {
    margin-top: .25rem;
    margin-bottom: .25rem;
  }

  .mv2-m {
    margin-top: .5rem;
    margin-bottom: .5rem;
  }

  .mv3-m {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .mv4-m {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .mv5-m {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }

  .mv6-m {
    margin-top: 8rem;
    margin-bottom: 8rem;
  }

  .mv7-m {
    margin-top: 16rem;
    margin-bottom: 16rem;
  }

  .mh0-m {
    margin-left: 0;
    margin-right: 0;
  }

  .mh1-m {
    margin-left: .25rem;
    margin-right: .25rem;
  }

  .mh2-m {
    margin-left: .5rem;
    margin-right: .5rem;
  }

  .mh3-m {
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .mh4-m {
    margin-left: 2rem;
    margin-right: 2rem;
  }

  .mh5-m {
    margin-left: 4rem;
    margin-right: 4rem;
  }

  .mh6-m {
    margin-left: 8rem;
    margin-right: 8rem;
  }

  .mh7-m {
    margin-left: 16rem;
    margin-right: 16rem;
  }

  .na1-m {
    margin: -.25rem;
  }

  .na2-m {
    margin: -.5rem;
  }

  .na3-m {
    margin: -1rem;
  }

  .na4-m {
    margin: -2rem;
  }

  .na5-m {
    margin: -4rem;
  }

  .na6-m {
    margin: -8rem;
  }

  .na7-m {
    margin: -16rem;
  }

  .nl1-m {
    margin-left: -.25rem;
  }

  .nl2-m {
    margin-left: -.5rem;
  }

  .nl3-m {
    margin-left: -1rem;
  }

  .nl4-m {
    margin-left: -2rem;
  }

  .nl5-m {
    margin-left: -4rem;
  }

  .nl6-m {
    margin-left: -8rem;
  }

  .nl7-m {
    margin-left: -16rem;
  }

  .nr1-m {
    margin-right: -.25rem;
  }

  .nr2-m {
    margin-right: -.5rem;
  }

  .nr3-m {
    margin-right: -1rem;
  }

  .nr4-m {
    margin-right: -2rem;
  }

  .nr5-m {
    margin-right: -4rem;
  }

  .nr6-m {
    margin-right: -8rem;
  }

  .nr7-m {
    margin-right: -16rem;
  }

  .nb1-m {
    margin-bottom: -.25rem;
  }

  .nb2-m {
    margin-bottom: -.5rem;
  }

  .nb3-m {
    margin-bottom: -1rem;
  }

  .nb4-m {
    margin-bottom: -2rem;
  }

  .nb5-m {
    margin-bottom: -4rem;
  }

  .nb6-m {
    margin-bottom: -8rem;
  }

  .nb7-m {
    margin-bottom: -16rem;
  }

  .nt1-m {
    margin-top: -.25rem;
  }

  .nt2-m {
    margin-top: -.5rem;
  }

  .nt3-m {
    margin-top: -1rem;
  }

  .nt4-m {
    margin-top: -2rem;
  }

  .nt5-m {
    margin-top: -4rem;
  }

  .nt6-m {
    margin-top: -8rem;
  }

  .nt7-m {
    margin-top: -16rem;
  }

  .strike-m {
    text-decoration: line-through;
  }

  .underline-m {
    text-decoration: underline;
  }

  .no-underline-m {
    text-decoration: none;
  }

  .tl-m {
    text-align: left;
  }

  .tr-m {
    text-align: right;
  }

  .tc-m {
    text-align: center;
  }

  .tj-m {
    text-align: justify;
  }

  .ttc-m {
    text-transform: capitalize;
  }

  .ttl-m {
    text-transform: lowercase;
  }

  .ttu-m {
    text-transform: uppercase;
  }

  .ttn-m {
    text-transform: none;
  }

  .f-6-m, .f-headline-m {
    font-size: 6rem;
  }

  .f-5-m, .f-subheadline-m {
    font-size: 5rem;
  }

  .f1-m {
    font-size: 3rem;
  }

  .f2-m {
    font-size: 2.25rem;
  }

  .f3-m {
    font-size: 1.5rem;
  }

  .f4-m {
    font-size: 1.25rem;
  }

  .f5-m {
    font-size: 1rem;
  }

  .f6-m {
    font-size: .875rem;
  }

  .f7-m {
    font-size: .75rem;
  }

  .measure-m {
    max-width: 30em;
  }

  .measure-wide-m {
    max-width: 34em;
  }

  .measure-narrow-m {
    max-width: 20em;
  }

  .indent-m {
    text-indent: 1em;
    margin-top: 0;
    margin-bottom: 0;
  }

  .small-caps-m {
    font-variant: small-caps;
  }

  .truncate-m {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .center-m {
    margin-left: auto;
    margin-right: auto;
  }

  .mr-auto-m {
    margin-right: auto;
  }

  .ml-auto-m {
    margin-left: auto;
  }

  .clip-m {
    clip: rect(1px 1px 1px 1px);
    clip: rect(1px, 1px, 1px, 1px);
    _position: absolute !important;
    position: fixed !important;
  }

  .ws-normal-m {
    white-space: normal;
  }

  .nowrap-m {
    white-space: nowrap;
  }

  .pre-m {
    white-space: pre;
  }

  .v-base-m {
    vertical-align: baseline;
  }

  .v-mid-m {
    vertical-align: middle;
  }

  .v-top-m {
    vertical-align: top;
  }

  .v-btm-m {
    vertical-align: bottom;
  }
}

@media screen and (min-width: 60em) {
  .aspect-ratio-l {
    height: 0;
    position: relative;
  }

  .aspect-ratio--16x9-l {
    padding-bottom: 56.25%;
  }

  .aspect-ratio--9x16-l {
    padding-bottom: 177.77%;
  }

  .aspect-ratio--4x3-l {
    padding-bottom: 75%;
  }

  .aspect-ratio--3x4-l {
    padding-bottom: 133.33%;
  }

  .aspect-ratio--6x4-l {
    padding-bottom: 66.6%;
  }

  .aspect-ratio--4x6-l {
    padding-bottom: 150%;
  }

  .aspect-ratio--8x5-l {
    padding-bottom: 62.5%;
  }

  .aspect-ratio--5x8-l {
    padding-bottom: 160%;
  }

  .aspect-ratio--7x5-l {
    padding-bottom: 71.42%;
  }

  .aspect-ratio--5x7-l {
    padding-bottom: 140%;
  }

  .aspect-ratio--1x1-l {
    padding-bottom: 100%;
  }

  .aspect-ratio--object-l {
    width: 100%;
    height: 100%;
    z-index: 100;
    position: absolute;
    inset: 0;
  }

  .cover-l {
    background-size: cover !important;
  }

  .contain-l {
    background-size: contain !important;
  }

  .bg-center-l {
    background-position: center;
    background-repeat: no-repeat;
  }

  .bg-top-l {
    background-position: top;
    background-repeat: no-repeat;
  }

  .bg-right-l {
    background-position: 100%;
    background-repeat: no-repeat;
  }

  .bg-bottom-l {
    background-position: bottom;
    background-repeat: no-repeat;
  }

  .bg-left-l {
    background-position: 0;
    background-repeat: no-repeat;
  }

  .outline-l {
    outline: 1px solid;
  }

  .outline-transparent-l {
    outline: 1px solid #0000;
  }

  .outline-0-l {
    outline: 0;
  }

  .ba-l {
    border-style: solid;
    border-width: 1px;
  }

  .bt-l {
    border-top-style: solid;
    border-top-width: 1px;
  }

  .br-l {
    border-right-style: solid;
    border-right-width: 1px;
  }

  .bb-l {
    border-bottom-style: solid;
    border-bottom-width: 1px;
  }

  .bl-l {
    border-left-style: solid;
    border-left-width: 1px;
  }

  .bn-l {
    border-style: none;
    border-width: 0;
  }

  .br0-l {
    border-radius: 0;
  }

  .br1-l {
    border-radius: .125rem;
  }

  .br2-l {
    border-radius: .25rem;
  }

  .br3-l {
    border-radius: .5rem;
  }

  .br4-l {
    border-radius: 1rem;
  }

  .br-100-l {
    border-radius: 100%;
  }

  .br-pill-l {
    border-radius: 9999px;
  }

  .br--bottom-l {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .br--top-l {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .br--right-l {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .br--left-l {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .br-inherit-l {
    border-radius: inherit;
  }

  .br-initial-l {
    border-radius: initial;
  }

  .br-unset-l {
    border-radius: unset;
  }

  .b--dotted-l {
    border-style: dotted;
  }

  .b--dashed-l {
    border-style: dashed;
  }

  .b--solid-l {
    border-style: solid;
  }

  .b--none-l {
    border-style: none;
  }

  .bw0-l {
    border-width: 0;
  }

  .bw1-l {
    border-width: .125rem;
  }

  .bw2-l {
    border-width: .25rem;
  }

  .bw3-l {
    border-width: .5rem;
  }

  .bw4-l {
    border-width: 1rem;
  }

  .bw5-l {
    border-width: 2rem;
  }

  .bt-0-l {
    border-top-width: 0;
  }

  .br-0-l {
    border-right-width: 0;
  }

  .bb-0-l {
    border-bottom-width: 0;
  }

  .bl-0-l {
    border-left-width: 0;
  }

  .shadow-1-l {
    box-shadow: 0 0 4px 2px #0003;
  }

  .shadow-2-l {
    box-shadow: 0 0 8px 2px #0003;
  }

  .shadow-3-l {
    box-shadow: 2px 2px 4px 2px #0003;
  }

  .shadow-4-l {
    box-shadow: 2px 2px 8px #0003;
  }

  .shadow-5-l {
    box-shadow: 4px 4px 8px #0003;
  }

  .top-0-l {
    top: 0;
  }

  .left-0-l {
    left: 0;
  }

  .right-0-l {
    right: 0;
  }

  .bottom-0-l {
    bottom: 0;
  }

  .top-1-l {
    top: 1rem;
  }

  .left-1-l {
    left: 1rem;
  }

  .right-1-l {
    right: 1rem;
  }

  .bottom-1-l {
    bottom: 1rem;
  }

  .top-2-l {
    top: 2rem;
  }

  .left-2-l {
    left: 2rem;
  }

  .right-2-l {
    right: 2rem;
  }

  .bottom-2-l {
    bottom: 2rem;
  }

  .top--1-l {
    top: -1rem;
  }

  .right--1-l {
    right: -1rem;
  }

  .bottom--1-l {
    bottom: -1rem;
  }

  .left--1-l {
    left: -1rem;
  }

  .top--2-l {
    top: -2rem;
  }

  .right--2-l {
    right: -2rem;
  }

  .bottom--2-l {
    bottom: -2rem;
  }

  .left--2-l {
    left: -2rem;
  }

  .absolute--fill-l {
    inset: 0;
  }

  .cl-l {
    clear: left;
  }

  .cr-l {
    clear: right;
  }

  .cb-l {
    clear: both;
  }

  .cn-l {
    clear: none;
  }

  .dn-l {
    display: none;
  }

  .di-l {
    display: inline;
  }

  .db-l {
    display: block;
  }

  .dib-l {
    display: inline-block;
  }

  .dit-l {
    display: inline-table;
  }

  .dt-l {
    display: table;
  }

  .dtc-l {
    display: table-cell;
  }

  .dt-row-l {
    display: table-row;
  }

  .dt-row-group-l {
    display: table-row-group;
  }

  .dt-column-l {
    display: table-column;
  }

  .dt-column-group-l {
    display: table-column-group;
  }

  .dt--fixed-l {
    table-layout: fixed;
    width: 100%;
  }

  .flex-l {
    display: flex;
  }

  .inline-flex-l {
    display: inline-flex;
  }

  .flex-auto-l {
    min-width: 0;
    min-height: 0;
    flex: auto;
  }

  .flex-none-l {
    flex: none;
  }

  .flex-column-l {
    flex-direction: column;
  }

  .flex-row-l {
    flex-direction: row;
  }

  .flex-wrap-l {
    flex-wrap: wrap;
  }

  .flex-nowrap-l {
    flex-wrap: nowrap;
  }

  .flex-wrap-reverse-l {
    flex-wrap: wrap-reverse;
  }

  .flex-column-reverse-l {
    flex-direction: column-reverse;
  }

  .flex-row-reverse-l {
    flex-direction: row-reverse;
  }

  .items-start-l {
    align-items: flex-start;
  }

  .items-end-l {
    align-items: flex-end;
  }

  .items-center-l {
    align-items: center;
  }

  .items-baseline-l {
    align-items: baseline;
  }

  .items-stretch-l {
    align-items: stretch;
  }

  .self-start-l {
    align-self: flex-start;
  }

  .self-end-l {
    align-self: flex-end;
  }

  .self-center-l {
    align-self: center;
  }

  .self-baseline-l {
    align-self: baseline;
  }

  .self-stretch-l {
    align-self: stretch;
  }

  .justify-start-l {
    justify-content: flex-start;
  }

  .justify-end-l {
    justify-content: flex-end;
  }

  .justify-center-l {
    justify-content: center;
  }

  .justify-between-l {
    justify-content: space-between;
  }

  .justify-around-l {
    justify-content: space-around;
  }

  .content-start-l {
    align-content: flex-start;
  }

  .content-end-l {
    align-content: flex-end;
  }

  .content-center-l {
    align-content: center;
  }

  .content-between-l {
    align-content: space-between;
  }

  .content-around-l {
    align-content: space-around;
  }

  .content-stretch-l {
    align-content: stretch;
  }

  .order-0-l {
    order: 0;
  }

  .order-1-l {
    order: 1;
  }

  .order-2-l {
    order: 2;
  }

  .order-3-l {
    order: 3;
  }

  .order-4-l {
    order: 4;
  }

  .order-5-l {
    order: 5;
  }

  .order-6-l {
    order: 6;
  }

  .order-7-l {
    order: 7;
  }

  .order-8-l {
    order: 8;
  }

  .order-last-l {
    order: 99999;
  }

  .flex-grow-0-l {
    flex-grow: 0;
  }

  .flex-grow-1-l {
    flex-grow: 1;
  }

  .flex-shrink-0-l {
    flex-shrink: 0;
  }

  .flex-shrink-1-l {
    flex-shrink: 1;
  }

  .fl-l {
    float: left;
    _display: inline;
  }

  .fr-l {
    float: right;
    _display: inline;
  }

  .fn-l {
    float: none;
  }

  .i-l {
    font-style: italic;
  }

  .fs-normal-l {
    font-style: normal;
  }

  .normal-l {
    font-weight: normal;
  }

  .b-l {
    font-weight: bold;
  }

  .fw1-l {
    font-weight: 100;
  }

  .fw2-l {
    font-weight: 200;
  }

  .fw3-l {
    font-weight: 300;
  }

  .fw4-l {
    font-weight: 400;
  }

  .fw5-l {
    font-weight: 500;
  }

  .fw6-l {
    font-weight: 600;
  }

  .fw7-l {
    font-weight: 700;
  }

  .fw8-l {
    font-weight: 800;
  }

  .fw9-l {
    font-weight: 900;
  }

  .h1-l {
    height: 1rem;
  }

  .h2-l {
    height: 2rem;
  }

  .h3-l {
    height: 4rem;
  }

  .h4-l {
    height: 8rem;
  }

  .h5-l {
    height: 16rem;
  }

  .h-25-l {
    height: 25%;
  }

  .h-50-l {
    height: 50%;
  }

  .h-75-l {
    height: 75%;
  }

  .h-100-l {
    height: 100%;
  }

  .min-h-100-l {
    min-height: 100%;
  }

  .vh-25-l {
    height: 25vh;
  }

  .vh-50-l {
    height: 50vh;
  }

  .vh-75-l {
    height: 75vh;
  }

  .vh-100-l {
    height: 100vh;
  }

  .min-vh-100-l {
    min-height: 100vh;
  }

  .h-auto-l {
    height: auto;
  }

  .h-inherit-l {
    height: inherit;
  }

  .tracked-l {
    letter-spacing: .1em;
  }

  .tracked-tight-l {
    letter-spacing: -.05em;
  }

  .tracked-mega-l {
    letter-spacing: .25em;
  }

  .lh-solid-l {
    line-height: 1;
  }

  .lh-title-l {
    line-height: 1.25;
  }

  .lh-copy-l {
    line-height: 1.5;
  }

  .mw-100-l {
    max-width: 100%;
  }

  .mw1-l {
    max-width: 1rem;
  }

  .mw2-l {
    max-width: 2rem;
  }

  .mw3-l {
    max-width: 4rem;
  }

  .mw4-l {
    max-width: 8rem;
  }

  .mw5-l {
    max-width: 16rem;
  }

  .mw6-l {
    max-width: 32rem;
  }

  .mw7-l {
    max-width: 48rem;
  }

  .mw8-l {
    max-width: 64rem;
  }

  .mw9-l {
    max-width: 96rem;
  }

  .mw-none-l {
    max-width: none;
  }

  .w1-l {
    width: 1rem;
  }

  .w2-l {
    width: 2rem;
  }

  .w3-l {
    width: 4rem;
  }

  .w4-l {
    width: 8rem;
  }

  .w5-l {
    width: 16rem;
  }

  .w-10-l {
    width: 10%;
  }

  .w-20-l {
    width: 20%;
  }

  .w-25-l {
    width: 25%;
  }

  .w-30-l {
    width: 30%;
  }

  .w-33-l {
    width: 33%;
  }

  .w-34-l {
    width: 34%;
  }

  .w-40-l {
    width: 40%;
  }

  .w-50-l {
    width: 50%;
  }

  .w-60-l {
    width: 60%;
  }

  .w-70-l {
    width: 70%;
  }

  .w-75-l {
    width: 75%;
  }

  .w-80-l {
    width: 80%;
  }

  .w-90-l {
    width: 90%;
  }

  .w-100-l {
    width: 100%;
  }

  .w-third-l {
    width: 33.3333%;
  }

  .w-two-thirds-l {
    width: 66.6667%;
  }

  .w-auto-l {
    width: auto;
  }

  .overflow-visible-l {
    overflow: visible;
  }

  .overflow-hidden-l {
    overflow: hidden;
  }

  .overflow-scroll-l {
    overflow: scroll;
  }

  .overflow-auto-l {
    overflow: auto;
  }

  .overflow-x-visible-l {
    overflow-x: visible;
  }

  .overflow-x-hidden-l {
    overflow-x: hidden;
  }

  .overflow-x-scroll-l {
    overflow-x: scroll;
  }

  .overflow-x-auto-l {
    overflow-x: auto;
  }

  .overflow-y-visible-l {
    overflow-y: visible;
  }

  .overflow-y-hidden-l {
    overflow-y: hidden;
  }

  .overflow-y-scroll-l {
    overflow-y: scroll;
  }

  .overflow-y-auto-l {
    overflow-y: auto;
  }

  .static-l {
    position: static;
  }

  .relative-l {
    position: relative;
  }

  .absolute-l {
    position: absolute;
  }

  .fixed-l {
    position: fixed;
  }

  .rotate-45-l {
    transform: rotate(45deg);
  }

  .rotate-90-l {
    transform: rotate(90deg);
  }

  .rotate-135-l {
    transform: rotate(135deg);
  }

  .rotate-180-l {
    transform: rotate(180deg);
  }

  .rotate-225-l {
    transform: rotate(225deg);
  }

  .rotate-270-l {
    transform: rotate(270deg);
  }

  .rotate-315-l {
    transform: rotate(315deg);
  }

  .pa0-l {
    padding: 0;
  }

  .pa1-l {
    padding: .25rem;
  }

  .pa2-l {
    padding: .5rem;
  }

  .pa3-l {
    padding: 1rem;
  }

  .pa4-l {
    padding: 2rem;
  }

  .pa5-l {
    padding: 4rem;
  }

  .pa6-l {
    padding: 8rem;
  }

  .pa7-l {
    padding: 16rem;
  }

  .pl0-l {
    padding-left: 0;
  }

  .pl1-l {
    padding-left: .25rem;
  }

  .pl2-l {
    padding-left: .5rem;
  }

  .pl3-l {
    padding-left: 1rem;
  }

  .pl4-l {
    padding-left: 2rem;
  }

  .pl5-l {
    padding-left: 4rem;
  }

  .pl6-l {
    padding-left: 8rem;
  }

  .pl7-l {
    padding-left: 16rem;
  }

  .pr0-l {
    padding-right: 0;
  }

  .pr1-l {
    padding-right: .25rem;
  }

  .pr2-l {
    padding-right: .5rem;
  }

  .pr3-l {
    padding-right: 1rem;
  }

  .pr4-l {
    padding-right: 2rem;
  }

  .pr5-l {
    padding-right: 4rem;
  }

  .pr6-l {
    padding-right: 8rem;
  }

  .pr7-l {
    padding-right: 16rem;
  }

  .pb0-l {
    padding-bottom: 0;
  }

  .pb1-l {
    padding-bottom: .25rem;
  }

  .pb2-l {
    padding-bottom: .5rem;
  }

  .pb3-l {
    padding-bottom: 1rem;
  }

  .pb4-l {
    padding-bottom: 2rem;
  }

  .pb5-l {
    padding-bottom: 4rem;
  }

  .pb6-l {
    padding-bottom: 8rem;
  }

  .pb7-l {
    padding-bottom: 16rem;
  }

  .pt0-l {
    padding-top: 0;
  }

  .pt1-l {
    padding-top: .25rem;
  }

  .pt2-l {
    padding-top: .5rem;
  }

  .pt3-l {
    padding-top: 1rem;
  }

  .pt4-l {
    padding-top: 2rem;
  }

  .pt5-l {
    padding-top: 4rem;
  }

  .pt6-l {
    padding-top: 8rem;
  }

  .pt7-l {
    padding-top: 16rem;
  }

  .pv0-l {
    padding-top: 0;
    padding-bottom: 0;
  }

  .pv1-l {
    padding-top: .25rem;
    padding-bottom: .25rem;
  }

  .pv2-l {
    padding-top: .5rem;
    padding-bottom: .5rem;
  }

  .pv3-l {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .pv4-l {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .pv5-l {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  .pv6-l {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }

  .pv7-l {
    padding-top: 16rem;
    padding-bottom: 16rem;
  }

  .ph0-l {
    padding-left: 0;
    padding-right: 0;
  }

  .ph1-l {
    padding-left: .25rem;
    padding-right: .25rem;
  }

  .ph2-l {
    padding-left: .5rem;
    padding-right: .5rem;
  }

  .ph3-l {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .ph4-l {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .ph5-l {
    padding-left: 4rem;
    padding-right: 4rem;
  }

  .ph6-l {
    padding-left: 8rem;
    padding-right: 8rem;
  }

  .ph7-l {
    padding-left: 16rem;
    padding-right: 16rem;
  }

  .ma0-l {
    margin: 0;
  }

  .ma1-l {
    margin: .25rem;
  }

  .ma2-l {
    margin: .5rem;
  }

  .ma3-l {
    margin: 1rem;
  }

  .ma4-l {
    margin: 2rem;
  }

  .ma5-l {
    margin: 4rem;
  }

  .ma6-l {
    margin: 8rem;
  }

  .ma7-l {
    margin: 16rem;
  }

  .ml0-l {
    margin-left: 0;
  }

  .ml1-l {
    margin-left: .25rem;
  }

  .ml2-l {
    margin-left: .5rem;
  }

  .ml3-l {
    margin-left: 1rem;
  }

  .ml4-l {
    margin-left: 2rem;
  }

  .ml5-l {
    margin-left: 4rem;
  }

  .ml6-l {
    margin-left: 8rem;
  }

  .ml7-l {
    margin-left: 16rem;
  }

  .mr0-l {
    margin-right: 0;
  }

  .mr1-l {
    margin-right: .25rem;
  }

  .mr2-l {
    margin-right: .5rem;
  }

  .mr3-l {
    margin-right: 1rem;
  }

  .mr4-l {
    margin-right: 2rem;
  }

  .mr5-l {
    margin-right: 4rem;
  }

  .mr6-l {
    margin-right: 8rem;
  }

  .mr7-l {
    margin-right: 16rem;
  }

  .mb0-l {
    margin-bottom: 0;
  }

  .mb1-l {
    margin-bottom: .25rem;
  }

  .mb2-l {
    margin-bottom: .5rem;
  }

  .mb3-l {
    margin-bottom: 1rem;
  }

  .mb4-l {
    margin-bottom: 2rem;
  }

  .mb5-l {
    margin-bottom: 4rem;
  }

  .mb6-l {
    margin-bottom: 8rem;
  }

  .mb7-l {
    margin-bottom: 16rem;
  }

  .mt0-l {
    margin-top: 0;
  }

  .mt1-l {
    margin-top: .25rem;
  }

  .mt2-l {
    margin-top: .5rem;
  }

  .mt3-l {
    margin-top: 1rem;
  }

  .mt4-l {
    margin-top: 2rem;
  }

  .mt5-l {
    margin-top: 4rem;
  }

  .mt6-l {
    margin-top: 8rem;
  }

  .mt7-l {
    margin-top: 16rem;
  }

  .mv0-l {
    margin-top: 0;
    margin-bottom: 0;
  }

  .mv1-l {
    margin-top: .25rem;
    margin-bottom: .25rem;
  }

  .mv2-l {
    margin-top: .5rem;
    margin-bottom: .5rem;
  }

  .mv3-l {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .mv4-l {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .mv5-l {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }

  .mv6-l {
    margin-top: 8rem;
    margin-bottom: 8rem;
  }

  .mv7-l {
    margin-top: 16rem;
    margin-bottom: 16rem;
  }

  .mh0-l {
    margin-left: 0;
    margin-right: 0;
  }

  .mh1-l {
    margin-left: .25rem;
    margin-right: .25rem;
  }

  .mh2-l {
    margin-left: .5rem;
    margin-right: .5rem;
  }

  .mh3-l {
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .mh4-l {
    margin-left: 2rem;
    margin-right: 2rem;
  }

  .mh5-l {
    margin-left: 4rem;
    margin-right: 4rem;
  }

  .mh6-l {
    margin-left: 8rem;
    margin-right: 8rem;
  }

  .mh7-l {
    margin-left: 16rem;
    margin-right: 16rem;
  }

  .na1-l {
    margin: -.25rem;
  }

  .na2-l {
    margin: -.5rem;
  }

  .na3-l {
    margin: -1rem;
  }

  .na4-l {
    margin: -2rem;
  }

  .na5-l {
    margin: -4rem;
  }

  .na6-l {
    margin: -8rem;
  }

  .na7-l {
    margin: -16rem;
  }

  .nl1-l {
    margin-left: -.25rem;
  }

  .nl2-l {
    margin-left: -.5rem;
  }

  .nl3-l {
    margin-left: -1rem;
  }

  .nl4-l {
    margin-left: -2rem;
  }

  .nl5-l {
    margin-left: -4rem;
  }

  .nl6-l {
    margin-left: -8rem;
  }

  .nl7-l {
    margin-left: -16rem;
  }

  .nr1-l {
    margin-right: -.25rem;
  }

  .nr2-l {
    margin-right: -.5rem;
  }

  .nr3-l {
    margin-right: -1rem;
  }

  .nr4-l {
    margin-right: -2rem;
  }

  .nr5-l {
    margin-right: -4rem;
  }

  .nr6-l {
    margin-right: -8rem;
  }

  .nr7-l {
    margin-right: -16rem;
  }

  .nb1-l {
    margin-bottom: -.25rem;
  }

  .nb2-l {
    margin-bottom: -.5rem;
  }

  .nb3-l {
    margin-bottom: -1rem;
  }

  .nb4-l {
    margin-bottom: -2rem;
  }

  .nb5-l {
    margin-bottom: -4rem;
  }

  .nb6-l {
    margin-bottom: -8rem;
  }

  .nb7-l {
    margin-bottom: -16rem;
  }

  .nt1-l {
    margin-top: -.25rem;
  }

  .nt2-l {
    margin-top: -.5rem;
  }

  .nt3-l {
    margin-top: -1rem;
  }

  .nt4-l {
    margin-top: -2rem;
  }

  .nt5-l {
    margin-top: -4rem;
  }

  .nt6-l {
    margin-top: -8rem;
  }

  .nt7-l {
    margin-top: -16rem;
  }

  .strike-l {
    text-decoration: line-through;
  }

  .underline-l {
    text-decoration: underline;
  }

  .no-underline-l {
    text-decoration: none;
  }

  .tl-l {
    text-align: left;
  }

  .tr-l {
    text-align: right;
  }

  .tc-l {
    text-align: center;
  }

  .tj-l {
    text-align: justify;
  }

  .ttc-l {
    text-transform: capitalize;
  }

  .ttl-l {
    text-transform: lowercase;
  }

  .ttu-l {
    text-transform: uppercase;
  }

  .ttn-l {
    text-transform: none;
  }

  .f-6-l, .f-headline-l {
    font-size: 6rem;
  }

  .f-5-l, .f-subheadline-l {
    font-size: 5rem;
  }

  .f1-l {
    font-size: 3rem;
  }

  .f2-l {
    font-size: 2.25rem;
  }

  .f3-l {
    font-size: 1.5rem;
  }

  .f4-l {
    font-size: 1.25rem;
  }

  .f5-l {
    font-size: 1rem;
  }

  .f6-l {
    font-size: .875rem;
  }

  .f7-l {
    font-size: .75rem;
  }

  .measure-l {
    max-width: 30em;
  }

  .measure-wide-l {
    max-width: 34em;
  }

  .measure-narrow-l {
    max-width: 20em;
  }

  .indent-l {
    text-indent: 1em;
    margin-top: 0;
    margin-bottom: 0;
  }

  .small-caps-l {
    font-variant: small-caps;
  }

  .truncate-l {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .center-l {
    margin-left: auto;
    margin-right: auto;
  }

  .mr-auto-l {
    margin-right: auto;
  }

  .ml-auto-l {
    margin-left: auto;
  }

  .clip-l {
    clip: rect(1px 1px 1px 1px);
    clip: rect(1px, 1px, 1px, 1px);
    _position: absolute !important;
    position: fixed !important;
  }

  .ws-normal-l {
    white-space: normal;
  }

  .nowrap-l {
    white-space: nowrap;
  }

  .pre-l {
    white-space: pre;
  }

  .v-base-l {
    vertical-align: baseline;
  }

  .v-mid-l {
    vertical-align: middle;
  }

  .v-top-l {
    vertical-align: top;
  }

  .v-btm-l {
    vertical-align: bottom;
  }
}

.CodeMirror {
  height: 300px;
  color: #000;
  direction: ltr;
  font-family: monospace;
}

.CodeMirror-lines {
  padding: 4px 0;
}

.CodeMirror pre.CodeMirror-line, .CodeMirror pre.CodeMirror-line-like {
  padding: 0 4px;
}

.CodeMirror-scrollbar-filler, .CodeMirror-gutter-filler {
  background-color: #fff;
}

.CodeMirror-gutters {
  white-space: nowrap;
  background-color: #f7f7f7;
  border-right: 1px solid #ddd;
}

.CodeMirror-linenumber {
  min-width: 20px;
  text-align: right;
  color: #999;
  white-space: nowrap;
  padding: 0 3px 0 5px;
}

.CodeMirror-guttermarker {
  color: #000;
}

.CodeMirror-guttermarker-subtle {
  color: #999;
}

.CodeMirror-cursor {
  width: 0;
  border-left: 1px solid #000;
  border-right: none;
}

.CodeMirror div.CodeMirror-secondarycursor {
  border-left: 1px solid silver;
}

.cm-fat-cursor .CodeMirror-cursor {
  width: auto;
  background: #7e7;
  border: 0 !important;
}

.cm-fat-cursor div.CodeMirror-cursors {
  z-index: 1;
}

.cm-fat-cursor .CodeMirror-line::-moz-selection, .cm-fat-cursor .CodeMirror-line > span::-moz-selection, .cm-fat-cursor .CodeMirror-line > span > span::-moz-selection {
  background: none;
}

.cm-fat-cursor .CodeMirror-line::selection, .cm-fat-cursor .CodeMirror-line > span::selection, .cm-fat-cursor .CodeMirror-line > span > span::selection {
  background: none;
}

.cm-fat-cursor {
  caret-color: #0000;
}

@keyframes blink {
  50% {
    background-color: #0000;
  }
}

.cm-tab {
  text-decoration: inherit;
  display: inline-block;
}

.CodeMirror-rulers {
  position: absolute;
  inset: -50px 0 0;
  overflow: hidden;
}

.CodeMirror-ruler {
  border-left: 1px solid #ccc;
  position: absolute;
  top: 0;
  bottom: 0;
}

.cm-s-default .cm-header {
  color: #00f;
}

.cm-s-default .cm-quote {
  color: #090;
}

.cm-negative {
  color: #d44;
}

.cm-positive {
  color: #292;
}

.cm-header, .cm-strong {
  font-weight: bold;
}

.cm-em {
  font-style: italic;
}

.cm-link {
  text-decoration: underline;
}

.cm-strikethrough {
  text-decoration: line-through;
}

.cm-s-default .cm-keyword {
  color: #708;
}

.cm-s-default .cm-atom {
  color: #219;
}

.cm-s-default .cm-number {
  color: #164;
}

.cm-s-default .cm-def {
  color: #00f;
}

.cm-s-default .cm-variable-2 {
  color: #05a;
}

.cm-s-default .cm-variable-3, .cm-s-default .cm-type {
  color: #085;
}

.cm-s-default .cm-comment {
  color: #a50;
}

.cm-s-default .cm-string {
  color: #a11;
}

.cm-s-default .cm-string-2 {
  color: #f50;
}

.cm-s-default .cm-meta, .cm-s-default .cm-qualifier {
  color: #555;
}

.cm-s-default .cm-builtin {
  color: #30a;
}

.cm-s-default .cm-bracket {
  color: #997;
}

.cm-s-default .cm-tag {
  color: #170;
}

.cm-s-default .cm-attribute {
  color: #00c;
}

.cm-s-default .cm-hr {
  color: #999;
}

.cm-s-default .cm-link {
  color: #00c;
}

.cm-s-default .cm-error, .cm-invalidchar {
  color: red;
}

.CodeMirror-composing {
  border-bottom: 2px solid;
}

div.CodeMirror span.CodeMirror-matchingbracket {
  color: #0b0;
}

div.CodeMirror span.CodeMirror-nonmatchingbracket {
  color: #a22;
}

.CodeMirror-matchingtag {
  background: #ff96004d;
}

.CodeMirror-activeline-background {
  background: #e8f2ff;
}

.CodeMirror {
  background: #fff;
  position: relative;
  overflow: hidden;
}

.CodeMirror-scroll {
  height: 100%;
  z-index: 0;
  outline: none;
  margin-bottom: -50px;
  margin-right: -50px;
  padding-bottom: 50px;
  position: relative;
  overflow: scroll !important;
}

.CodeMirror-sizer {
  border-right: 50px solid #0000;
  position: relative;
}

.CodeMirror-vscrollbar, .CodeMirror-hscrollbar, .CodeMirror-scrollbar-filler, .CodeMirror-gutter-filler {
  z-index: 6;
  outline: none;
  display: none;
  position: absolute;
}

.CodeMirror-vscrollbar {
  top: 0;
  right: 0;
  overflow-x: hidden;
  overflow-y: scroll;
}

.CodeMirror-hscrollbar {
  bottom: 0;
  left: 0;
  overflow-x: scroll;
  overflow-y: hidden;
}

.CodeMirror-scrollbar-filler {
  bottom: 0;
  right: 0;
}

.CodeMirror-gutter-filler {
  bottom: 0;
  left: 0;
}

.CodeMirror-gutters {
  min-height: 100%;
  z-index: 3;
  position: absolute;
  top: 0;
  left: 0;
}

.CodeMirror-gutter {
  white-space: normal;
  height: 100%;
  vertical-align: top;
  margin-bottom: -50px;
  display: inline-block;
}

.CodeMirror-gutter-wrapper {
  z-index: 4;
  position: absolute;
  background: none !important;
  border: none !important;
}

.CodeMirror-gutter-background {
  z-index: 4;
  position: absolute;
  top: 0;
  bottom: 0;
}

.CodeMirror-gutter-elt {
  cursor: default;
  z-index: 4;
  position: absolute;
}

.CodeMirror-gutter-wrapper ::-moz-selection {
  background-color: #0000;
}

.CodeMirror-gutter-wrapper ::selection {
  background-color: #0000;
}

.CodeMirror-lines {
  cursor: text;
  min-height: 1px;
}

.CodeMirror pre.CodeMirror-line, .CodeMirror pre.CodeMirror-line-like {
  font-family: inherit;
  font-size: inherit;
  white-space: pre;
  word-wrap: normal;
  line-height: inherit;
  color: inherit;
  z-index: 2;
  -webkit-tap-highlight-color: transparent;
  -webkit-font-variant-ligatures: contextual;
  font-variant-ligatures: contextual;
  background: none;
  border-width: 0;
  border-radius: 0;
  margin: 0;
  position: relative;
  overflow: visible;
}

.CodeMirror-wrap pre.CodeMirror-line, .CodeMirror-wrap pre.CodeMirror-line-like {
  word-wrap: break-word;
  white-space: pre-wrap;
  word-break: normal;
}

.CodeMirror-linebackground {
  z-index: 0;
  position: absolute;
  inset: 0;
}

.CodeMirror-linewidget {
  z-index: 2;
  padding: .1px;
  position: relative;
}

.CodeMirror-rtl pre {
  direction: rtl;
}

.CodeMirror-code {
  outline: none;
}

.CodeMirror-scroll, .CodeMirror-sizer, .CodeMirror-gutter, .CodeMirror-gutters, .CodeMirror-linenumber {
  box-sizing: content-box;
}

.CodeMirror-measure {
  width: 100%;
  height: 0;
  visibility: hidden;
  position: absolute;
  overflow: hidden;
}

.CodeMirror-cursor {
  pointer-events: none;
  position: absolute;
}

.CodeMirror-measure pre {
  position: static;
}

div.CodeMirror-cursors {
  visibility: hidden;
  z-index: 3;
  position: relative;
}

div.CodeMirror-dragcursors, .CodeMirror-focused div.CodeMirror-cursors {
  visibility: visible;
}

.CodeMirror-selected {
  background: #d9d9d9;
}

.CodeMirror-focused .CodeMirror-selected {
  background: #d7d4f0;
}

.CodeMirror-crosshair {
  cursor: crosshair;
}

.CodeMirror-line::-moz-selection, .CodeMirror-line > span::-moz-selection, .CodeMirror-line > span > span::-moz-selection {
  background: #d7d4f0;
}

.CodeMirror-line::selection, .CodeMirror-line > span::selection, .CodeMirror-line > span > span::selection {
  background: #d7d4f0;
}

.cm-searching {
  background-color: #ff06;
}

.cm-force-border {
  padding-right: .1px;
}

@media print {
  .CodeMirror div.CodeMirror-cursors {
    visibility: hidden;
  }
}

.cm-tab-wrap-hack:after {
  content: "";
}

span.CodeMirror-selectedtext {
  background: none;
}

.CodeMirror-dialog {
  background: inherit;
  z-index: 15;
  color: inherit;
  padding: .1em .8em;
  position: absolute;
  left: 0;
  right: 0;
  overflow: hidden;
}

.CodeMirror-dialog-top {
  border-bottom: 1px solid #eee;
  top: 0;
}

.CodeMirror-dialog-bottom {
  border-top: 1px solid #eee;
  bottom: 0;
}

.CodeMirror-dialog input {
  width: 20em;
  color: inherit;
  background: none;
  border: none;
  outline: none;
  font-family: monospace;
}

.CodeMirror-dialog button {
  font-size: 70%;
}

.CodeMirror-foldmarker {
  color: #00f;
  text-shadow: 1px 1px 2px #b9f, -1px -1px 2px #b9f, 1px -1px 2px #b9f, -1px 1px 2px #b9f;
  cursor: pointer;
  font-family: arial;
  line-height: .3;
}

.CodeMirror-foldgutter {
  width: .7em;
}

.CodeMirror-foldgutter-open, .CodeMirror-foldgutter-folded {
  cursor: pointer;
}

.CodeMirror-foldgutter-open:after {
  content: "▾";
}

.CodeMirror-foldgutter-folded:after {
  content: "▸";
}

.CodeMirror-lint-markers {
  width: 16px;
}

.CodeMirror-lint-tooltip {
  color: #000;
  white-space: pre;
  white-space: pre-wrap;
  z-index: 100;
  max-width: 600px;
  opacity: 0;
  -o-transition: opacity .4s;
  background-color: #ffd;
  border: 1px solid #000;
  border-radius: 4px;
  padding: 2px 5px;
  font-family: monospace;
  font-size: 10pt;
  transition: opacity .4s;
  position: fixed;
  overflow: hidden;
}

.CodeMirror-lint-mark {
  background-position: 0 100%;
  background-repeat: repeat-x;
}

.CodeMirror-lint-mark-warning {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAADCAYAAAC09K7GAAAAAXNSR0IArs4c6QAAAAZiS0dEAP8A/wD/oL2nkwAAAAlwSFlzAAALEwAACxMBAJqcGAAAAAd0SU1FB9sJFhQXEbhTg7YAAAAZdEVYdENvbW1lbnQAQ3JlYXRlZCB3aXRoIEdJTVBXgQ4XAAAAMklEQVQI12NkgIIvJ3QXMjAwdDN+OaEbysDA4MPAwNDNwMCwiOHLCd1zX07o6kBVGQEAKBANtobskNMAAAAASUVORK5CYII=");
}

.CodeMirror-lint-mark-error {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAADCAYAAAC09K7GAAAAAXNSR0IArs4c6QAAAAZiS0dEAP8A/wD/oL2nkwAAAAlwSFlzAAALEwAACxMBAJqcGAAAAAd0SU1FB9sJDw4cOCW1/KIAAAAZdEVYdENvbW1lbnQAQ3JlYXRlZCB3aXRoIEdJTVBXgQ4XAAAAHElEQVQI12NggIL/DAz/GdA5/xkY/qPKMDAwAADLZwf5rvm+LQAAAABJRU5ErkJggg==");
}

.CodeMirror-lint-marker {
  cursor: pointer;
  height: 16px;
  width: 16px;
  vertical-align: middle;
  background-position: center;
  background-repeat: no-repeat;
  display: inline-block;
  position: relative;
}

.CodeMirror-lint-message {
  background-position: 0 0;
  background-repeat: no-repeat;
  padding-left: 18px;
}

.CodeMirror-lint-marker-warning, .CodeMirror-lint-message-warning {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAMAAAAoLQ9TAAAANlBMVEX/uwDvrwD/uwD/uwD/uwD/uwD/uwD/uwD/uwD6twD/uwAAAADurwD2tQD7uAD+ugAAAAD/uwDhmeTRAAAADHRSTlMJ8mN1EYcbmiixgACm7WbuAAAAVklEQVR42n3PUQqAIBBFUU1LLc3u/jdbOJoW1P08DA9Gba8+YWJ6gNJoNYIBzAA2chBth5kLmG9YUoG0NHAUwFXwO9LuBQL1giCQb8gC9Oro2vp5rncCIY8L8uEx5ZkAAAAASUVORK5CYII=");
}

.CodeMirror-lint-marker-error, .CodeMirror-lint-message-error {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAMAAAAoLQ9TAAAAHlBMVEW7AAC7AACxAAC7AAC7AAAAAAC4AAC5AAD///+7AAAUdclpAAAABnRSTlMXnORSiwCK0ZKSAAAATUlEQVR42mWPOQ7AQAgDuQLx/z8csYRmPRIFIwRGnosRrpamvkKi0FTIiMASR3hhKW+hAN6/tIWhu9PDWiTGNEkTtIOucA5Oyr9ckPgAWm0GPBog6v4AAAAASUVORK5CYII=");
}

.CodeMirror-lint-marker-multiple {
  width: 100%;
  height: 100%;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAAHCAMAAADzjKfhAAAACVBMVEUAAAAAAAC/v7914kyHAAAAAXRSTlMAQObYZgAAACNJREFUeNo1ioEJAAAIwmz/H90iFFSGJgFMe3gaLZ0od+9/AQZ0ADosbYraAAAAAElFTkSuQmCC");
  background-position: 100% 100%;
  background-repeat: no-repeat;
}

.CodeMirror-lint-line-error {
  background-color: #b74c5114;
}

.CodeMirror-lint-line-warning {
  background-color: #ffd3001a;
}

* {
  scrollbar-color: var(--scrollbar-fg) var(--scrollbar-bg);
  scrollbar-width: thin;
}

html, body {
  height: 100%;
  color: var(--page-fg);
  background-color: var(--page-bg);
}

body {
  --header-height: 30px;
  font-size: 14px;
}

body, .sans-serif {
  font-family: Source Sans Pro, sans-serif;
}

.no-select {
  -webkit-user-select: none;
  user-select: none;
}

a {
  color: var(--link-color);
}

input {
  color: inherit;
  background: none;
  border: 1px solid;
  border-radius: 1px;
  padding: .1rem .2rem;
}

button {
  color: inherit;
  cursor: pointer;
  opacity: .9;
  background-color: #0001;
  border: none;
  border-radius: .25rem;
  padding: .5rem 1rem;
}

button:hover {
  opacity: 1;
}

button.compact {
  padding: 3px 9px;
}

button[disabled] {
  opacity: .6;
  cursor: not-allowed;
}

:is(h1, h2, h3):first-child {
  margin-top: 0;
}

p.info {
  border: 1px solid;
  border-radius: .2rem;
  padding: .2rem .5rem;
}

svg.icon {
  vertical-align: middle;
  stroke-linecap: round;
  stroke-linejoin: round;
  margin: 3px;
  display: inline-block;
}

svg.icon .spin {
  transform-origin: center;
  animation: spin 2s linear infinite;
}

th, td {
  border: 1px solid var(--border-color);
  vertical-align: top;
  padding: .5rem;
}

main .top-layout {
  width: 100vw;
  height: 100vh;
  grid-template-rows: [r1] auto[r2] minmax(0, 1fr)[r3];
  grid-template-columns: [c1] auto[c2] minmax(0, 1fr)[c3] minmax(0, 1fr)[c4];
  display: grid;
}

main .top-layout > header {
  grid-column: c1 / -1;
}

main .top-layout .editor-pane {
  grid-area: r2 / c2 / span 1 / span 2;
}

main .top-layout .result-pane + .editor-pane {
  grid-column-end: span 1;
}

main .top-layout > header {
  z-index: 20;
  background-color: var(--page-bg2);
  border-bottom: 1px solid var(--border-color);
  height: var(--header-height);
  justify-content: space-between;
  align-items: stretch;
  display: flex;
  position: relative;
}

.nav-link {
  color: var(--page-fg);
  cursor: pointer;
  border-radius: 0;
  align-items: center;
  display: flex;
  position: relative;
}

.nav-link.active {
  background-color: var(--active-tab-bg);
  color: var(--active-tab-fg);
}

.nav-link .tip {
  z-index: 9;
  background-color: var(--nav-link-hover-bg);
  color: var(--nav-link-hover-fg);
  white-space: pre;
  border-radius: 3px;
  padding: 6px;
  font-size: 1rem;
  display: none;
  position: absolute;
  box-shadow: 0 0 12px #0009;
}

.nav-link .tip.right {
  top: 50%;
  left: calc(100% + 6px);
  transform: translateY(-50%);
}

.nav-link:not(.active):hover .tip {
  display: block;
}

:root {
  --monospace-font: "Source Code Pro";
  --monospace-font-size: 16px;
}

tt, code, pre, kbd, .mono, .CodeMirror pre.CodeMirror-line, .CodeMirror pre.CodeMirror-line-like {
  font-family: var(--monospace-font), monospace;
  line-height: 1.3;
}

pre {
  -moz-tab-size: 4;
  tab-size: 4;
}

.CodeMirror pre.CodeMirror-line, .CodeMirror pre.CodeMirror-line-like {
  font-size: var(--monospace-font-size);
  line-height: calc(var(--monospace-font-size) * 1.3);
}

.editor-pane, .result-pane {
  flex-direction: column;
  display: flex;
  position: relative;
}

.editor-pane > .toolbar, .result-pane > .toolbar {
  position: relative;
}

.editor-pane > .toolbar > .bar, .result-pane > .toolbar > .bar {
  border-bottom: 1px solid var(--border-color);
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.editor-pane > .toolbar > .bar > *, .result-pane > .toolbar > .bar > * {
  margin: 0 6px;
}

.editor-pane > .toolbar > .peripherals, .result-pane > .toolbar > .peripherals {
  width: 100%;
  position: absolute;
  top: 100%;
}

.editor-pane > .toolbar > .peripherals > *, .result-pane > .toolbar > .peripherals > * {
  height: 70vh;
  width: 500px;
  z-index: 15;
  background-color: #fff;
  border-bottom-left-radius: 6px;
  flex-direction: column;
  display: flex;
  position: absolute;
  box-shadow: -2px 2px 6px #0000004d;
}

.editor-pane > .toolbar > .peripherals > .right, .result-pane > .toolbar > .peripherals > .right {
  right: 0;
}

.editor-pane > .toolbar > .peripherals > * > *, .result-pane > .toolbar > .peripherals > * > * {
  padding: 6px;
}

.editor-pane > .toolbar > .peripherals > * > header, .result-pane > .toolbar > .peripherals > * > header {
  border-bottom: 1px solid var(--border-color);
}

.editor-pane > .toolbar > .peripherals > * > header h2, .result-pane > .toolbar > .peripherals > * > header h2 {
  margin: 9px 3px;
}

.editor-pane > .toolbar > .peripherals > * > section, .result-pane > .toolbar > .peripherals > * > section {
  flex-grow: 1;
  overflow-y: auto;
}

.editor-pane > .toolbar > .peripherals > * > footer, .result-pane > .toolbar > .peripherals > * > footer {
  border-top: 1px solid var(--border-color);
  justify-content: space-between;
  display: flex;
}

.editor-pane > .body, .result-pane > .body {
  flex: 1;
  overflow-y: auto;
}

.result-pane {
  border-left: 1px solid var(--border-color);
}

.result-pane.error .message {
  font-size: 1.1em;
}

.result-pane.error th, .result-pane.error td {
  border-color: #ffdecf;
}

.result-pane.error > * {
  padding-left: 6px;
  padding-right: 6px;
}

.result-pane .messages li + li {
  margin-top: 6px;
}

.result-pane .response + .response {
  border-top: 2px dashed #acf;
  margin-top: 1em;
}

.result-pane .response .tab-bar {
  border-bottom: 1px solid var(--active-tab-bg);
  display: flex;
}

.result-pane .response .tab-bar .nav-link {
  border-radius: .25rem .25rem 0 0;
}

.result-pane .response .tab-bar + * {
  border-bottom: 1px solid var(--active-tab-bg);
}

.result-pane .response img, .result-pane .response svg {
  max-height: 200px;
  max-width: 100%;
  margin: .3rem;
}

.result-pane .response iframe {
  height: 200px;
}

.result-pane .line-nums {
  border-right: 1px solid var(--border-color);
  -webkit-user-select: none;
  user-select: none;
  margin-right: .5ch;
  padding: 0 .5ch 0 calc(3px + 1ch);
}

.result-pane .CodeMirror {
  height: auto;
  background: #00000008;
}

.result-pane .CodeMirror .CodeMirror-lines {
  cursor: default;
}

.result-pane .CodeMirror .CodeMirror-lines span:not(.CodeMirror-foldmarker) {
  cursor: text;
}

.result-pane .CodeMirror .CodeMirror-cursor {
  display: none;
}

.result-pane pre {
  padding-bottom: 1px;
}

.editor-pane div.CodeMirror {
  height: 100%;
  background: var(--page-bg);
  color: var(--page-fg);
  text-shadow: 1px 1px 2px #00000008;
  z-index: 0;
}

.editor-pane div.CodeMirror .CodeMirror-code > div {
  transition: background-color .3s ease-out;
}

.editor-pane div.CodeMirror .flash {
  background-color: #ddd;
}

.editor-pane div.CodeMirror .line-javascript {
  background-color: var(--js-background);
}

.editor-pane div.CodeMirror.CodeMirror-focused {
  opacity: 1;
  background: none;
}

.editor-pane div.CodeMirror .CodeMirror-cursor {
  border-left-color: currentColor;
}

.editor-pane div.CodeMirror .CodeMirror-dialog {
  background-color: var(--page-bg);
  border-color: var(--page-bg2);
  font-size: 1.2em;
}

.editor-pane div.CodeMirror .CodeMirror-activeline-background {
  background-color: var(--cursor-line-bg);
}

.editor-pane div.CodeMirror .CodeMirror-activeline-gutter {
  font-weight: bold;
}

.editor-pane div.CodeMirror .CodeMirror-gutter-wrapper .icon {
  background-color: var(--primary-2);
  color: var(--primary-9);
  cursor: pointer;
}

.editor-pane div.CodeMirror span.CodeMirror-matchingbracket {
  background-color: var(--washed-green);
  color: var(--dark-green);
}

.editor-pane div.CodeMirror span.CodeMirror-nonmatchingbracket {
  background-color: var(--dark-red);
  color: var(--washed-red);
}

.editor-pane div.CodeMirror .CodeMirror-cursor {
  border-left-width: 2px;
}

.sidebar {
  border-right: 1px solid var(--border-color);
  position: relative;
}

.sidebar .tab-bar {
  background-color: var(--page-bg2);
}

.sidebar .tab-bar .nav-link {
  padding: 9px;
  font-size: 2em;
}

.sidebar .content {
  min-width: 90px;
  max-width: 60vw;
  overflow-x: hidden;
  overflow-y: auto;
}

.sidebar .grip {
  border: 1px solid var(--border-color);
  width: 1.2ch;
  height: 5ch;
  z-index: 9;
  background-color: var(--page-bg);
  cursor: ew-resize;
  border-radius: .6ch;
  position: absolute;
  top: 100px;
  right: -.6ch;
}

.CodeMirror-selected, ::selection {
  text-shadow: none;
  background: var(--selection-bg) !important;
  color: var(--selection-fg) !important;
}

.modal {
  top: var(--header-height);
  background-color: var(--page-bg2);
  border-width: 0 0 1px 1px;
  border-color: var(--border-color);
  z-index: 15;
  border-style: solid;
  border-bottom-left-radius: 6px;
  overflow: hidden;
  box-shadow: 0 0 72px #0006;
}

.modal > header {
  border-bottom: 1px solid var(--border-color);
}

.modal > section {
  flex-grow: 1;
}

.modal > footer {
  border-top: 1px solid var(--border-color);
}

.modal2-mask {
  z-index: 100;
  opacity: .8;
  position: absolute;
  inset: 0;
}

.modal2 {
  max-width: 50vw;
  max-height: 90vh;
  z-index: 110;
  border-radius: .3rem;
  flex-direction: column;
  display: flex;
  position: absolute;
  top: 5vh;
  left: 50%;
  overflow: hidden;
  transform: translateX(-50%);
  box-shadow: 0 9px 72px #0009;
}

.mask {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.loading {
  background-color: var(--page-bg);
  border-radius: 6px;
  padding: 12px;
  font-size: 3em;
  display: inline-block;
  box-shadow: 0 0 72px #0004;
}

label {
  cursor: pointer;
}

.auth-pane {
  display: flex;
}

.auth-pane .auth-options {
  flex-direction: column;
  display: flex;
}

.auth-pane .auth-options button {
  margin: 6px 0;
  font-size: 1.1em;
}

form.grid, form > .grid {
  grid: auto / minmax(90px, auto) minmax(300px, auto);
  align-items: center;
  margin: 0 auto;
  display: grid;
}

form.grid > *, form > .grid > * {
  margin: 6px;
}

form.grid select, form > .grid select {
  max-width: 400px;
}

form.grid input[type="text"], form > .grid input[type="text"] {
  border: 1px solid var(--border-color);
  border-radius: 3px;
  padding: 6px;
  box-shadow: inset 0 0 2px #0002;
}

form.grid input[type="text"]:focus, form > .grid input[type="text"]:focus {
  border-color: var(--active-tab-bg);
  box-shadow: 0 0 6px var(--active-tab-bg);
  outline: none;
}

form.grid input[type="text"]::placeholder, form > .grid input[type="text"]::placeholder {
  font-style: italic;
}

form.grid input[type="number"], form > .grid input[type="number"] {
  max-width: 9ch;
}

.toasts {
  width: 400px;
  z-index: 99;
  pointer-events: none;
}

.toasts > * {
  pointer-events: auto;
  animation: toast .2s;
  position: relative;
}

.toasts > .close {
  animation: toast-close .2s;
}

.toasts > :hover .close-btn {
  display: block;
}

.toasts .close-btn {
  cursor: pointer;
  width: 1.6em;
  height: 1.6em;
  border-radius: 50%;
  padding: 0;
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  transform: translate(-50%, -50%);
}

details.fold {
  width: 100%;
  display: inline-block;
  position: relative;
}

details.fold summary {
  display: block;
}

details.fold summary .cm-comment {
  opacity: .7;
  margin-left: 1ch;
}

details.fold summary .cm-comment:hover {
  opacity: 1;
}

details.fold summary .line-nums {
  cursor: pointer;
}

details.fold summary:before {
  content: "▶";
  pointer-events: none;
  font-size: .8em;
  display: block;
  position: absolute;
  left: 3px;
}

details.fold[open] > summary:before {
  content: "▼ ";
}

.provider-block ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.provider-block ul a {
  color: inherit;
  padding: 3px 6px;
  text-decoration: none;
  display: block;
}

.provider-block ul a:hover {
  background-color: var(--page-bg2);
}

.provider-block li > ul {
  margin-left: 1.3em;
}

.provider-block + .provider-block {
  margin-top: 2em;
}

.stack-view {
  position: relative;
  overflow: hidden;
}

.stack-view > :not(:first-child) {
  height: 100%;
  width: 100%;
  background-color: var(--page-bg);
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

@keyframes toast {
  from {
    opacity: 0;
    transform: translateY(2rem);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

@keyframes toast-close {
  from {
    opacity: 1;
    transform: none;
  }

  to {
    opacity: 0;
    transform: translateY(-2em);
  }
}

@keyframes ghost {
  from {
    opacity: 1;
    transform: scale(1.5);
  }

  to {
    opacity: 0;
    transform: translateY(-3em)scale(2.5);
  }
}

@keyframes spin {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

body {
  --page-bg: var(--gray-9);
  --page-bg2: var(--gray-8);
  --page-fg: var(--gray-2);
  --scrollbar-bg: var(--page-bg);
  --scrollbar-fg: var(--gray-5);
  --border-color: var(--gray-6);
  --link-color: var(--blue-5);
  --selection-bg: var(--blue-8);
  --selection-fg: var(--blue-1);
  --gutter-bg: var(--gray-8);
  --gutter-fg: var(--gray-6);
  --gutter-marker-fg: var(--red-3);
  --gutter-marker-subtle-fg: var(--gray-6);
  --cursor-color: var(--gray-4);
  --cm-comment: var(--primary-3);
  --cm-atom: var(--primary-3);
  --cm-number: var(--primary-3);
  --cm-property: var(--green-5);
  --cm-attribute: var(--green-5);
  --cm-keyword: var(--red-3);
  --cm-string: var(--yellow-4);
  --cm-string-2: var(--yellow-4);
  --cm-variable: var(--green-5);
  --cm-variable-2: var(--blue-5);
  --cm-def: var(--blue-5);
  --cm-bracket: var(--gray-2);
  --cm-tag: var(--red-3);
  --cm-link: var(--primary-5);
  --cm-error-bg: var(--red-3);
  --cm-error-fg: var(--gray-4);
  --cursor-line-bg: var(--blue-9);
  --match-bracket-fg: var(--gray-8);
  --match-bracket-bg: var(--blue-5);
  --js-background: var(--yellow-9);
  --active-tab-fg: var(--primary-9);
  --active-tab-bg: var(--primary-3);
  --nav-link-hover-bg: var(--primary-9);
  --nav-link-hover-fg: var(--primary-1);
}

body .nav-link:hover:not(.active) {
  background-color: var(--nav-link-hover-bg);
  color: var(--nav-link-hover-fg);
}

body .cm-tab {
  background-image: url("data:image/svg+xml;utf8,<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"1rem\" height=\"1rem\" viewBox=\"0 0 24 24\"><path d=\"M 3 9 l 3 3 l -3 3\" stroke=\"black\" stroke-width=\"2\" fill=\"none\"/></svg>");
  background-repeat: no-repeat;
}

body button.primary {
  background-color: var(--primary-3);
  color: var(--primary-9);
}

body button.danger-light {
  background-color: var(--red-9);
  color: var(--red-3);
}

body button.danger-light:hover {
  background-color: var(--red-3);
  color: var(--red-9);
}

body .provider-block a.active {
  background-color: var(--primary-3);
  color: var(--primary-9);
}

body .status.is-2xx {
  background-color: var(--green-3);
  color: var(--green-9);
}

body .status.is-3xx {
  background-color: var(--blue-3);
  color: var(--blue-9);
}

body .status.is-4xx, body .status.is-5xx {
  background-color: var(--red-3);
  color: var(--red-9);
}

body .flash {
  background-color: var(--primary-7);
}

body .response tr:hover {
  background-color: var(--gray-8);
}

body .result-pane.error {
  background-color: var(--red-9);
  color: var(--red-1);
}

body p.info {
  background-color: var(--blue-9);
  color: var(--blue-1);
}

body .modal2-mask {
  background-color: var(--page-bg2);
}

body .modal2 {
  background-color: var(--page-bg);
}

body.dark {
  --page-bg: var(--gray-2);
  --page-bg2: var(--gray-1);
  --page-fg: var(--gray-7);
  --scrollbar-bg: var(--page-bg);
  --scrollbar-fg: var(--gray-6);
  --border-color: var(--gray-3);
  --link-color: var(--blue-5);
  --selection-bg: var(--blue-2);
  --selection-fg: var(--blue-9);
  --gutter-bg: var(--gray-1);
  --gutter-fg: var(--gray-5);
  --gutter-marker-fg: var(--red-3);
  --gutter-marker-subtle-fg: var(--gray-4);
  --cursor-color: var(--gray-6);
  --cm-comment: var(--primary-7);
  --cm-atom: var(--primary-5);
  --cm-number: var(--primary-5);
  --cm-property: var(--green-5);
  --cm-attribute: var(--green-5);
  --cm-keyword: var(--red-5);
  --cm-string: var(--yellow-7);
  --cm-variable: var(--green-5);
  --cm-variable-2: var(--blue-5);
  --cm-def: var(--blue-5);
  --cm-bracket: var(--gray-7);
  --cm-tag: var(--red-6);
  --cm-link: var(--primary-5);
  --cm-error-bg: var(--red-3);
  --cm-error-fg: var(--gray-6);
  --cursor-line-bg: var(--gray-3);
  --match-bracket-fg: var(--gray-9);
  --match-bracket-bg: transparent;
  --js-background: var(--yellow-1);
  --active-tab-fg: var(--primary-9);
  --active-tab-bg: var(--primary-2);
  --nav-link-hover-bg: var(--primary-1);
  --nav-link-hover-fg: var(--primary-9);
}

body.dark .nav-link:hover:not(.active) {
  background-color: var(--nav-link-hover-bg);
  color: var(--nav-link-hover-fg);
}

body.dark .cm-tab {
  background-image: url("data:image/svg+xml;utf8,<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"1rem\" height=\"1rem\" viewBox=\"0 0 24 24\"><path d=\"M 3 9 l 3 3 l -3 3\" stroke=\"white\" stroke-width=\"2\" fill=\"none\"/></svg>");
  background-repeat: no-repeat;
}

body.dark button.primary {
  background-color: var(--primary-3);
  color: var(--primary-9);
}

body.dark button.danger-light {
  background-color: var(--red-1);
  color: var(--red-9);
}

body.dark button.danger-light:hover {
  background-color: var(--red-3);
  color: var(--red-9);
}

body.dark .provider-block a.active {
  background-color: var(--primary-3);
  color: var(--primary-9);
}

body.dark .status.is-2xx {
  background-color: var(--green-3);
  color: var(--green-9);
}

body.dark .status.is-3xx {
  background-color: var(--blue-3);
  color: var(--blue-9);
}

body.dark .status.is-4xx, body.dark .status.is-5xx {
  background-color: var(--red-3);
  color: var(--red-9);
}

body.dark .flash {
  background-color: var(--primary-7);
}

body.dark .response tr:hover {
  background-color: var(--gray-3);
}

body.dark .result-pane.error {
  background-color: var(--red-1);
  color: var(--red-9);
}

body.dark p.info {
  background-color: var(--blue-3);
  color: var(--blue-9);
}

body.dark .modal2-mask {
  background-color: var(--page-bg);
}

body.dark .modal2 {
  background-color: var(--page-bg2);
}

body[data-palette="tomorrow"] {
  --gray-1: #1d1f21;
  --gray-2: #282a2e;
  --gray-3: #4d4d4c;
  --gray-4: #969896;
  --gray-5: #8e908c;
  --gray-6: #d6d6d6;
  --gray-7: #e0e0e0;
  --gray-8: #f7f7f7;
  --gray-9: #fff;
  --primary-1: #07383c;
  --primary-2: #0f5257;
  --primary-3: #266e73;
  --primary-4: #2f7e83;
  --primary-5: #3d979e;
  --primary-6: #39bcc6;
  --primary-7: #5cced6;
  --primary-8: #baeff3;
  --primary-9: #ecfdfe;
  --blue-1: #142f52;
  --blue-2: #224777;
  --blue-3: #315481;
  --blue-4: #3b659b;
  --blue-5: #4575b5;
  --blue-6: #5985c0;
  --blue-7: #85a6d1;
  --blue-8: #bccee6;
  --blue-9: #e2ecf8;
  --green-1: #313d00;
  --green-2: #415200;
  --green-3: #526600;
  --green-4: #5e7500;
  --green-5: #6e8a00;
  --green-6: #7e9b08;
  --green-7: #d3e87d;
  --green-8: #e7f3ba;
  --green-9: #f6fae5;
  --yellow-1: #382c00;
  --yellow-2: #382c00;
  --yellow-3: #996e00;
  --yellow-4: #cc9200;
  --yellow-5: #ebb800;
  --yellow-6: #f5cd3d;
  --yellow-7: #fae69e;
  --yellow-8: #fcf3cf;
  --yellow-9: #fdf9ed;
  --red-1: #400d0d;
  --red-2: #621313;
  --red-3: #801919;
  --red-4: #a62121;
  --red-5: #c33c3c;
  --red-6: #e67f7f;
  --red-7: #eaa;
  --red-8: #f7d4d4;
  --red-9: #fbeaea;
  --extra-1: #f5871f;
  --extra-2: #3e999f;
  --extra-3: #8959a8;
  --extra-4: #a3685a;
}

body[data-palette="base16"] {
  --gray-1: #151515;
  --gray-2: #202020;
  --gray-3: #303030;
  --gray-4: #505050;
  --gray-5: gray;
  --gray-6: #b0b0b0;
  --gray-7: #e0e0e0;
  --gray-8: #f5f5f5;
  --gray-9: #fff;
  --primary-1: #3f273a;
  --primary-2: #5f3a58;
  --primary-3: #7e4e75;
  --primary-4: #9b5f8f;
  --primary-5: #aa749f;
  --primary-6: #bf97b7;
  --primary-7: #e2b6d9;
  --primary-8: #f1daec;
  --primary-9: #f9f0f7;
  --blue-1: #173845;
  --blue-2: #204e60;
  --blue-3: #2d6d86;
  --blue-4: #528fa7;
  --blue-5: #699fb5;
  --blue-6: #92b9c9;
  --blue-7: #bbd4dd;
  --blue-8: #dde9ee;
  --blue-9: #eef4f7;
  --green-1: #2b321a;
  --green-2: #3c4724;
  --green-3: #505e31;
  --green-4: #758a47;
  --green-5: #91aa5a;
  --green-6: #b0c289;
  --green-7: #d2dcbc;
  --green-8: #e8eedd;
  --green-9: #f4f6ee;
  --yellow-1: #29210a;
  --yellow-2: #493c12;
  --yellow-3: #795c06;
  --yellow-4: #a37a00;
  --yellow-5: #cca000;
  --yellow-6: #ffd000;
  --yellow-7: #ffea80;
  --yellow-8: #fff7cc;
  --yellow-9: #fbf8e9;
  --red-1: #642626;
  --red-2: #813131;
  --red-3: #aa4141;
  --red-4: #bd4242;
  --red-5: #c75757;
  --red-6: #c66;
  --red-7: #e6b3b3;
  --red-8: #f2d9d9;
  --red-9: #faf0f0;
}

body[data-palette="solarized"] {
  --gray-1: #002b36;
  --gray-2: #073642;
  --gray-3: #115662;
  --gray-4: #586e75;
  --gray-5: #839496;
  --gray-6: #93a1a1;
  --gray-7: #e0d8c3;
  --gray-8: #eee8d5;
  --gray-9: #fdf6e3;
  --primary-1: #073c37;
  --primary-2: #0b504b;
  --primary-3: #136c65;
  --primary-4: #1d877e;
  --primary-5: #2aa298;
  --primary-6: #33ccbf;
  --primary-7: #57dbd0;
  --primary-8: #b6fbf5;
  --primary-9: #ddfdfb;
  --blue-1: #03233a;
  --blue-2: #083a5e;
  --blue-3: #0f578a;
  --blue-4: #1a73b3;
  --blue-5: #278bd3;
  --blue-6: #4da6e6;
  --blue-7: #75bdf0;
  --blue-8: #aad8f8;
  --blue-9: #f0f9ff;
  --green-1: #353d00;
  --green-2: #505c00;
  --green-3: #6f8000;
  --green-4: #8da300;
  --green-5: #a8c200;
  --green-6: #d4f500;
  --green-7: #e8ff52;
  --green-8: #f1ff94;
  --green-9: #f6ffbd;
  --yellow-1: #3d2e00;
  --yellow-2: #5c4500;
  --yellow-3: #806000;
  --yellow-4: #997300;
  --yellow-5: #b38600;
  --yellow-6: #ffbf00;
  --yellow-7: #ffd966;
  --yellow-8: #ffeebd;
  --yellow-9: #fff5d6;
  --red-1: #2e0605;
  --red-2: #4e0504;
  --red-3: #8b100e;
  --red-4: #b41b18;
  --red-5: #dc312e;
  --red-6: #ee6563;
  --red-7: #f8a2a0;
  --red-8: #fccfcf;
  --red-9: #ffebeb;
  --nav-link-hover-bg: var(--gray-7);
}

body[data-palette="solarized"].dark {
  --nav-link-hover-bg: var(--gray-2);
}

body[data-palette="mono"] {
  --gray-1: #151515;
  --gray-2: #202020;
  --gray-3: #303030;
  --gray-4: #505050;
  --gray-5: gray;
  --gray-6: #b0b0b0;
  --gray-7: #e0e0e0;
  --gray-8: #f5f5f5;
  --gray-9: #fff;
  --primary-1: var(--gray-1);
  --primary-2: var(--gray-2);
  --primary-3: var(--gray-3);
  --primary-4: var(--gray-4);
  --primary-5: var(--gray-5);
  --primary-6: var(--gray-6);
  --primary-7: var(--gray-7);
  --primary-8: var(--gray-8);
  --primary-9: var(--gray-9);
  --blue-1: var(--gray-1);
  --blue-2: var(--gray-2);
  --blue-3: var(--gray-3);
  --blue-4: var(--gray-4);
  --blue-5: var(--gray-5);
  --blue-6: var(--gray-6);
  --blue-7: var(--gray-7);
  --blue-8: var(--gray-8);
  --blue-9: var(--gray-9);
  --green-1: var(--gray-1);
  --green-2: var(--gray-2);
  --green-3: var(--gray-3);
  --green-4: var(--gray-4);
  --green-5: var(--gray-5);
  --green-6: var(--gray-6);
  --green-7: var(--gray-7);
  --green-8: var(--gray-8);
  --green-9: var(--gray-9);
  --yellow-1: var(--gray-1);
  --yellow-2: var(--gray-2);
  --yellow-3: var(--gray-3);
  --yellow-4: var(--gray-4);
  --yellow-5: var(--gray-5);
  --yellow-6: var(--gray-6);
  --yellow-7: var(--gray-7);
  --yellow-8: var(--gray-8);
  --yellow-9: var(--gray-9);
  --red-1: var(--gray-1);
  --red-2: var(--gray-2);
  --red-3: var(--gray-3);
  --red-4: var(--gray-4);
  --red-5: var(--gray-5);
  --red-6: var(--gray-6);
  --red-7: var(--gray-7);
  --red-8: var(--gray-8);
  --red-9: var(--gray-9);
}

.cm-s-default .CodeMirror-gutters {
  background: var(--gutter-bg);
  border-right: 0;
}

.cm-s-default .CodeMirror-guttermarker {
  color: var(--gutter-marker-fg);
}

.cm-s-default .CodeMirror-guttermarker-subtle {
  color: var(--gutter-marker-subtle-fg);
}

.cm-s-default .CodeMirror-linenumber {
  color: var(--gutter-fg);
}

.cm-s-default .CodeMirror-cursor {
  border-left: 1px solid var(--cursor-color);
}

.cm-s-default span.cm-comment {
  color: var(--cm-comment);
}

.cm-s-default span.cm-atom {
  color: var(--cm-atom);
}

.cm-s-default span.cm-number {
  color: var(--cm-number);
}

.cm-s-default span.cm-attribute {
  color: var(--cm-attribute);
}

.cm-s-default span.cm-keyword {
  color: var(--cm-keyword);
}

.cm-s-default span.cm-string {
  color: var(--cm-string);
}

.cm-s-default span.cm-string-2 {
  color: var(--cm-string-2);
}

.cm-s-default span.cm-property {
  color: var(--cm-property);
}

.cm-s-default span.cm-variable {
  color: var(--cm-variable);
}

.cm-s-default span.cm-variable-2 {
  color: var(--cm-variable-2);
}

.cm-s-default span.cm-def {
  color: var(--cm-def);
}

.cm-s-default span.cm-bracket {
  color: var(--cm-bracket);
}

.cm-s-default span.cm-tag {
  color: var(--cm-tag);
}

.cm-s-default span.cm-link {
  color: var(--cm-link);
  cursor: pointer;
}

.cm-s-default span.cm-error {
  background: var(--cm-error-bg);
  color: var(--cm-error-fg);
}

.cm-s-default .CodeMirror-activeline-background {
  background: var(--cursor-line-bg);
}

.cm-s-default .CodeMirror-matchingbracket {
  color: var(--match-bracket-fg) !important;
  background-color: var(--match-bracket-bg) !important;
}

.old-domain-notice {
  background: var(--gray-1);
  color: var(--gray-9);
  width: 480px;
  border-radius: .25em;
  padding: 1em;
  font-size: 1.25em;
  position: fixed;
  top: 3em;
  left: 50%;
  transform: translateX(-50%);
  box-shadow: 0 12px 72px #0006;
}

/*# sourceMappingURL=index.fdde2993.css.map */
